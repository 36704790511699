import React, { memo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const CustomizedCheckbox = ({ label = "", name, disabled = false }) => {
  const { control, getValues } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          defaultValue={false}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Checkbox
              inputRef={ref}
              checked={value}
              disabled={disabled}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export default memo(CustomizedCheckbox);
