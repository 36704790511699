import { Box, Hidden, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import MenuItem from "layout/Menu/MenuItem/MenuItem";
import React, { useCallback, useState } from "react";
import { selectCurrentSelectedLevelZero, selectMenuItems, setCurrentSelectedLevelZero } from "redux/slices/menu";
import styles from "./styles";
import Submenu from "layout/Menu/Submenu/Submenu";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "assets/img/firecrux.svg";

const useStyles = makeStyles(styles);

const Menu = () => {
  const classes = useStyles();
  const menuItems = useSelector(selectMenuItems);
  const dispatch = useDispatch();

  //const [sidebar, setSidebar] = useState(0);
  const sidebar = useSelector(selectCurrentSelectedLevelZero);
  const [mobileMain, setMobileMain] = useState(true);

  const markMenuItem = useCallback((itemIdx) => {
    let clickedIndex = parseInt(itemIdx);
    dispatch(setCurrentSelectedLevelZero(clickedIndex));
    setMobileMain(false);
  }, []);

  const backToMain = () => {
    setMobileMain(true);
  };

  return (
    menuItems?.length > 0 && (
      <div className={classes.sidebar}>
        {/* mobile menu */}
        <Hidden mdUp>
          <nav className={clsx(classes.mobileMItems, !mobileMain && classes.visibleSubmenu)}>
            {!mobileMain && (
              <Box className={classes.back} onClick={backToMain}>
                <ChevronLeftIcon />
                {menuItems[sidebar].name}
              </Box>
            )}
            {mobileMain && (
              <>
                <ProfileInfo />
                <ul className={classes.mobileCategories}>
                  {menuItems.map((menu, index) => (
                    <MenuItem
                      main={mobileMain}
                      key={"sdbar." + index}
                      index={index}
                      onClickedItem={markMenuItem}
                      name={menu.name}
                      icon={menu.icon}
                    />
                  ))}
                </ul>
              </>
            )}

            {!mobileMain && <Submenu group={menuItems[sidebar].group} />}
          </nav>
        </Hidden>
        {/* normal */}
        <Hidden mdDown>
          <nav>
            <Stack className={classes.leftSidebar}>
              {menuItems.map((menu, index) => (
                <MenuItem
                  key={"sdbar." + index}
                  index={index}
                  isShow={sidebar === index}
                  name={menu.name}
                  icon={menu.icon}
                  onClickedItem={markMenuItem}
                />
              ))}
              <Box position="absolute" bottom="4vh">
                <ProfileInfo />
              </Box>
            </Stack>
          </nav>

          <div className={classes.rightSidebar}>
            <div className={classes.logoWrapper}>
              <Logo />
            </div>
            <div className={classes.submenuWrapper}>
              <Submenu group={menuItems[sidebar].group} />
            </div>
          </div>
        </Hidden>
      </div>
    )
  );
};
export default Menu;
