import React from "react";
import LiveStatusView from "modules/LiveDevice/LiveDeviceComponent/LiveStatusView";
import LiveCyclesView from "modules/LiveDevice/LiveDeviceComponent/LiveCyclesView";
import LiveRestartsView from "modules/LiveDevice/LiveDeviceComponent/LiveRestartsView";
import LiveImageView from "modules/LiveDevice/LiveDeviceComponent/LiveImageView";
import { Grid } from "@mui/material";
import LiveDetectionsView from "modules/LiveDevice/LiveDeviceComponent/LiveDetectionsView";
import LiveGalleryView from "modules/LiveDevice/LiveDeviceComponent/LiveGalleryView";

const LiveDeviceView = ({ data }) => {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <LiveImageView image={data?.image?.image} name={data?.image?.name} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <LiveStatusView
              tegra={data?.status?.tegra}
              isRecoil={data?.status?.teisRecoilgra}
              status={data?.status?.status}
              recoilToday={data?.status?.recoilToday}
              allRecoil={data?.status?.allRecoil}
              cycle={data?.status?.cycle}
              allCycle={data?.status?.allCycle}
              seasoning={data?.seasoning?.seasoning}
              seasoningType={data?.seasoning?.seasoningType}
            />
          </Grid>
          <Grid item xs={12}>
            <LiveRestartsView
              allErrors={data?.restarts?.allErrors}
              err1={data?.restarts?.err1}
              err2={data?.restarts?.err2}
              err3={data?.restarts?.err3}
            />
          </Grid>
          <Grid item xs={12}>
            <LiveCyclesView
              withDetection={data?.cycles?.withDetection}
              withoutDetection={data?.cycles?.withoutDetection}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LiveDetectionsView data={data} />
      </Grid>
      <Grid item xs={12}>
        <LiveGalleryView beforeImage={data?.beforeImage} />
      </Grid>
    </>
  );
};
export default LiveDeviceView;
