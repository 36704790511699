import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const LiveRestartsView = ({ allErrors, err1, err2, err3 }) => {
  const { t } = useTranslation("common");

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ background: "transparent", textAlign: "right" }} />
          <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_details_all")}</TableCell>
          <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_details_img")}</TableCell>
          <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_details_dc")}</TableCell>
          <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_details_e9")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="right">Today restarts</TableCell>
          <TableCell align="right">{allErrors}</TableCell>
          <TableCell align="right">{err1}</TableCell>
          <TableCell align="right">{err2}</TableCell>
          <TableCell align="right">{err3}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
export default memo(LiveRestartsView);
