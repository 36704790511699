import React from "react";
import { Navigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { LOCAL_STORAGE_NAMES } from "enums/LOCAL_STORAGE_NAMES";
import { INTERNAL_API as API } from "enums/INTERNAL_API";

const StartPage = () => {
  const lastLocation = reactLocalStorage.get(LOCAL_STORAGE_NAMES.LAST_LOCATION, undefined, true);
  const devicesURL = API.LIVE_DEVICE;
  const liveURL = API.LIVE;

  if (lastLocation && lastLocation.substring(0, devicesURL.length) === devicesURL) {
    return <Navigate to={lastLocation} replace />;
  } else {
    return <Navigate to={liveURL} replace />;
  }
};
export default StartPage;
