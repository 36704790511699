import { Grid } from "@mui/material";
import React from "react";
import DetailTableJournal from "modules/Devices/AllDeviceDetails/DetailTableJournal";
import DetailTableCycles from "modules/Devices/AllDeviceDetails/DetailTableCycles";
import DetailTableReboots from "modules/Devices/AllDeviceDetails/DetailTableReboots";
const AllDevicesDetailEvents = () => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} lg={6}>
        <DetailTableJournal />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DetailTableCycles />
      </Grid>
      <Grid item xs={12} lg={6}>
        <DetailTableReboots />
      </Grid>
    </Grid>
  );
};
export default AllDevicesDetailEvents;
