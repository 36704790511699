import { useState } from "react";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordion = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      disableGutters
      elevation={0}
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === true}
      onChange={() => setExpanded((prevState) => !prevState)}
      sx={{ background: "none" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
