import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@mui/material";
import React, { useRef } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectEditItem } from "redux/slices/crud";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import RoleModulePermision from "components/Edit/Role/RoleModulePermision";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditView from "components/EditView";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.ROLES;
const title = "settings_role_edit_label";
const moduleUrl = INTERNAL_API.ROLES;

const RoleEdit = () => {
  const { id } = useParams();
  const current = useSelector(selectEditItem);
  const { t } = useTranslation("common");

  const breadcrumbs = [
    { name: t("menu_settings"), href: "" },
    { name: t("settings_roles_label"), href: INTERNAL_API.ROLES },
    { name: t(title), href: `${INTERNAL_API.ROLES}/${id}` },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditView
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        preview={false}
        breadcrumbs={breadcrumbs}
      >
        <FormData id={id} current={current} refForm={refForm} />
      </EditView>
    </>
  );
};

const FormData = ({ id, refForm }) => {
  const { register, formState } = useFormContext();
  const { t } = useTranslation("common");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              label={t("role_edit_name")}
              name={"name"}
              inputRef={register}
              errors={formState?.errors}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField
              label={t("role_edit_code")}
              name={"code"}
              inputRef={register}
              errors={formState?.errors}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/*<Box sx={{textAlign: "right"}}>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="secondary"*/}
            {/*        size="small"*/}
            {/*        onClick={() => {*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Zaznacz wszystkie*/}
            {/*    </Button>*/}
            {/*</Box>*/}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Accordion square defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ p: 0 }}
              >
                <Typography variant="h4" component={"h4"}>
                  {t("role_edit_modules")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, mb: 5 }}>
                <RoleModulePermision refForm={refForm} id={id} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
      {/*<Paper sx={{p: 10, mb: 10}} elevation={8}>*/}
      {/*    <Accordion square>*/}
      {/*        <AccordionSummary*/}
      {/*            expandIcon={<ExpandMoreIcon/>}*/}
      {/*            aria-controls="panel1a-content"*/}
      {/*            id="panel1a-header"*/}
      {/*            sx={{p: 0}}*/}
      {/*        >*/}
      {/*            <Typography variant="h4" component={"h4"}>Funkcje</Typography>*/}
      {/*        </AccordionSummary>*/}
      {/*        <AccordionDetails sx={{p: 0, mb: 5}}>*/}
      {/*            <RoleFunctionPermision*/}
      {/*                error={errors}*/}
      {/*                register={register}*/}
      {/*            />*/}
      {/*        </AccordionDetails>*/}
      {/*    </Accordion>*/}
      {/*</Paper>*/}
    </>
  );
};

export default RoleEdit;
