const labelsRadialChartCycleOnOff = ["Cycles - detection On", "Cycles - detection Off"];
const labelsRadialChartDeviceDetectionOnOff = [
  "Devices - detection On",
  "Devices - detection Off",
  "Devices turned off",
];

const labelsChartDeviceDetectionOnOff = ["Cycle On", "Cycle Off"];

export { labelsRadialChartCycleOnOff, labelsChartDeviceDetectionOnOff, labelsRadialChartDeviceDetectionOnOff };
