import { memo } from "react";
import Switch from "@mui/material/Switch";
import { FormControlLabel, FormGroup, InputLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

const CustomizedSwitch = ({ topLabel = "", rightLabel = "", name, disabled = false, defaultValue = true }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <InputLabel>{topLabel}</InputLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={value === true || value === "true" || value === "PUBLISHED"}
                  color="secondary"
                  inputRef={ref}
                  onChange={onChange}
                  disabled={disabled}
                />
              }
              label={rightLabel}
            />
            <FormHelperText>{formState?.errors[name] ? formState?.errors[name].message : ""}</FormHelperText>
          </FormGroup>
        </>
      )}
    />
  );
};

export default memo(CustomizedSwitch);
