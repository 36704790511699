import { Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@mui/material/TableSortLabel";

export function DataTableHead({
  headCells,
  order,
  orderBy,
  onRequestSort,
  noCheckbox,
  handleCheckAllCheckboxes,
  checkedCheckboxAll,
}) {
  const { t } = useTranslation("common");

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!noCheckbox && (
          <TableCell sx={{ width: "5%" }}>
            <Checkbox size={"small"} onClick={handleCheckAllCheckboxes} checked={checkedCheckboxAll} />
          </TableCell>
        )}
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id + "-" + index}
            sx={headCell.numeric ? { textAlign: "right" } : { textAlign: "left" }}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {t(headCell.label)}
              </TableSortLabel>
            )}
            {!headCell.sortable && t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
