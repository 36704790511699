import { Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PaginationTable from "modules/Devices/AllDeviceDetails/PaginationTable";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useParams } from "react-router-dom";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.LOG;

const DetailTableJournal = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const [events, setEvents] = useState([]);
  const [isGetDataProgress, setIsGetDataProgress] = useState(true);

  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");
  const [maxPages, setMaxPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const itemPerPage = 10;

  const handleSort = (columnName) => {
    if (orderBy === columnName) {
      setOrder((prev) => (prev === "desc" ? "asc" : "desc"));
    } else {
      setOrderBy(columnName);
      setOrder("desc");
    }
  };

  useEffect(() => {
    getData();
  }, [actualPage, orderBy, order]);

  const getData = () => {
    setIsGetDataProgress(true);

    let url = endpoint;
    url += "?page=" + (actualPage - 1);
    url += "&limit=" + itemPerPage;
    url += "&order=" + orderBy;
    url += order === "desc" ? "%2Bdesc" : "";
    url += "&filter%5Bdevice%5D=" + id;

    backend
      .get(url)
      .then((response) => {
        if (isSuccess(response)) {
          setEvents(response.data.items);
          setMaxPages(response.data.pagination.totalPages);
        } else {
          console.error(response);
        }
        setIsGetDataProgress(false);
      })
      .catch((err) => {
        console.error(err);
        setIsGetDataProgress(false);
      });
  };

  const handleSetActualPage = (page) => {
    setActualPage(page);
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {isGetDataProgress ? (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant={"h5"}>{t("device_details_event_log")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table" sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell id="date" sortDirection={orderBy === "date" ? order : false} sx={{ width: "30%" }}>
                      <TableSortLabel
                        active={orderBy === "date"}
                        direction={orderBy === "date" ? order : "asc"}
                        onClick={() => handleSort("date")}
                      >
                        {t("table_date")}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell id="type" sortDirection={orderBy === "action" ? order : false}>
                      <TableSortLabel
                        active={orderBy === "action"}
                        direction={orderBy === "action" ? order : "asc"}
                        onClick={() => handleSort("action")}
                      >
                        {t("table_action")}
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((event) => (
                    <TableRow key={event.id} hover>
                      <TableCell>{event.date}</TableCell>
                      <TableCell>{event.action}</TableCell>
                    </TableRow>
                  ))}
                  {events?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={2}>{t("table_no_data")}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            {events?.length > 0 && (
              <PaginationTable
                maxPages={maxPages}
                actualPage={actualPage}
                itemPerPage={itemPerPage}
                setActualPage={handleSetActualPage}
                setItemPerPage={() => {}}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default DetailTableJournal;
