import { Grid, Paper } from "@mui/material";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import EditView from "components/EditView";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const title = "settings_app_label";
const endpoint = EXTERNAL_API.SETTINGS_APP;
const moduleUrl = INTERNAL_API.APP_SETTINGS;

const UserEdit = () => {
  const { t } = useTranslation("common");
  const [changePassword, setChangePassword] = useState(false);

  const breadcrumbs = [
    { name: t("menu_settings"), href: "" },
    { name: t(title), href: INTERNAL_API.APP_SETTINGS },
  ];

  const validationSchema = Yup.object({});

  const refForm = useRef(null);

  return (
    <>
      <EditView
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noBackButton
        editWithoutId
        noDeleteButton
        breadcrumbs={breadcrumbs}
      >
        <FormData changePassword={changePassword} setChangePassword={setChangePassword} />
      </EditView>
    </>
  );
};

const FormData = () => {
  const { register, formState } = useFormContext();
  const { t } = useTranslation("common");

  return (
    <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12}>
          <CustomizedTextField
            label={t("settings_app_key")}
            name={"key"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomizedTextField
            label={t("settings_app_token")}
            name={"token"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            label={t("settings_app_ticket_failure")}
            name={"ticketFailure"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            label={t("settings_app_ticket_error")}
            name={"ticketError"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            label={t("settings_app_ticket_other")}
            name={"ticketOther"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserEdit;
