import { Fragment, useEffect, useState } from "react";
import { Checkbox, Tooltip, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const RoleModulePermission = ({ id }) => {
  const { setValue } = useFormContext();
  const [menuItems, setMenuItems] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const { t } = useTranslation("common");

  useEffect(() => {
    const getData = async () => {
      const res = await backend.get(EXTERNAL_API.PERMISSION + id);
      if (isSuccess(res)) {
        setPermissions(res.data);
      }
      const resMenu = await backend.get(EXTERNAL_API.MENU_ALL);
      if (isSuccess(resMenu)) {
        setMenuItems(resMenu.data);
        console.log(resMenu.data);
      }
      setLoading(false);
    };

    getData();
  }, []);

  useEffect(() => {
    setValue("permissions", permissions);
  }, [permissions]);

  const handleCheckbox = (e, m, p) => {
    const b = e.target.checked;
    const res = permissions.filter((p) => p.menuItemId === m);
    let permission;
    if (res?.length > 0) {
      permission = res[0];
    } else {
      permission = { menuItemId: m };
    }
    permission[p] = b;
    setPermissions([...permissions.filter((p) => p.menuItemId !== m), permission]);
  };

  // useImperativeHandle(refForm, () => ({
  //     save() {
  //         handleSubmitPermission();
  //     },
  //
  // }));
  //
  // const handleSubmitPermission = () => {
  //     backend.post("/groups/permission/" + id, permissions).then((res) => {
  //         if (!isSuccess(res)) {
  //             console.error(res);
  //         }
  //     });
  // };

  const isClicked = (m, p) => {
    const res = permissions.filter((p) => p.menuItemId === m);
    if (res?.length > 0) {
      const permission = res[0][p];
      if (permission) return permission;
    }
    return false;
  };

  function renderItem(m) {
    return (
      <TableRow key={`ri.${m.id}`} hover>
        <TableCell>{m.title}</TableCell>
        <TableCell>
          <Checkbox
            color="default"
            onClick={(e) => handleCheckbox(e, m.id, "visibility")}
            inputProps={{ "aria-label": "secondary checkbox" }}
            checked={isClicked(m.id, "visibility")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="default"
            onClick={(e) => handleCheckbox(e, m.id, "creating")}
            checked={isClicked(m.id, "creating")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="default"
            onClick={(e) => handleCheckbox(e, m.id, "read")}
            checked={isClicked(m.id, "read")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="default"
            onClick={(e) => handleCheckbox(e, m.id, "write")}
            checked={isClicked(m.id, "write")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            color="default"
            checked={isClicked(m.id, "delete")}
            onClick={(e) => handleCheckbox(e, m.id, "delete")}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                  <Tooltip title={t("role_edit_visibility")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                    <Typography variant={"h5"}>V</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                  <Tooltip title={t("role_edit_create")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                    <Typography variant={"h5"}>C</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                  <Tooltip title={t("role_edit_read")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                    <Typography variant={"h5"}>R</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                  <Tooltip title={t("role_edit_update")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                    <Typography variant={"h5"}>U</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: 60, textAlign: "center", pb: 4 }}>
                  <Tooltip title={t("role_edit_delete")} placement="top" arrow={true} sx={{ mr: "10px" }}>
                    <Typography variant={"h5"}>D</Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menuItems.map((m) => {
                return (
                  <Fragment key={`rc.${m.id}`}>
                    <TableRow key={`mi.${m.id}`}>
                      <TableCell sx={{ fontWeight: 800 }}>{m.name}</TableCell>
                      <TableCell>
                        <Checkbox
                          color="default"
                          onClick={(e) => handleCheckbox(e, m.id, "visibility")}
                          inputProps={{ "aria-label": "secondary checkbox" }}
                          checked={isClicked(m.id, "visibility")}
                        />
                      </TableCell>
                      <TableCell colSpan={4}></TableCell>
                    </TableRow>
                    {m.group?.map((g) => g.pages?.map((p, idx) => renderItem(p, idx)))}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
export default RoleModulePermission;
