import { Card, CardContent, CardMedia, Stack, Tooltip, Typography } from "@mui/material";
import React, { memo, useState } from "react";
import Box from "@mui/material/Box";
import * as color from "assets/styles/jss/common/colors";
import { useTranslation } from "react-i18next";

const placeholder = "time.png";

const LiveImageBox = ({ name, id, image, deviceId, time, date, handleImagePreview }) => {
  const { t } = useTranslation("common");

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (e) => {
    e.target.error = null;
    e.target.src = placeholder;
    setIsError(true);

    setTimeout(setOriginalImage, 12000, e);
  };

  const setOriginalImage = (e) => {
    e.target.src = image;
  };

  return (
    <Tooltip title={name}>
      <Card
        onClick={isError || isLoading ? () => {} : () => handleImagePreview(id)}
        sx={{
          marginLeft: 0,
          marginRight: 3,
          display: "flex",
          background: color.submenuBackgroundHover,
          border: 0.1,
          borderColor: color.menuSelected,
          borderRadius: 2,
          cursor: "pointer",
        }}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
          <CardMedia
            component={"img"}
            sx={{
              height: 160,
              width: 160,
              background: "black",
            }}
            image={`${image}?w=160&h=160&fit=crop&auto=format`}
            title={name}
            loading="lazy"
            onError={handleError}
            onLoad={() => setIsLoading(false)}
          />
          <CardContent sx={{ minWidth: 0.4 }}>
            <Stack direction="column" justifyContent="space-around" alignItems="flex-start" spacing={0}>
              <Box>
                <Typography variant="body" sx={{ whiteSpace: "nowrap" }}>
                  {`${t("live_image_device")} `}
                  <Box component="span" className="devices-font">
                    {deviceId}
                  </Box>
                </Typography>
              </Box>
              <Box>
                <Typography variant="time" sx={{ whiteSpace: "nowrap" }}>
                  {time}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                  {t("live_image_detected")}
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                  {`${t("live_image_today")}, ${date}`}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Stack>
      </Card>
    </Tooltip>
  );
};

export default memo(LiveImageBox);
