import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import EditView from "components/EditView";
import React, { useRef } from "react";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSwitch from "components/Edit/CustomizedSwitch";
import { useFormContext } from "react-hook-form";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { useParams } from "react-router-dom";

const endpoint = "/hall/";
const title = "halls_edit_label";
const moduleUrl = INTERNAL_API.HALLS;

const AllDevicesNew = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const breadcrumbs = [
    { name: t("menu_devices"), href: "" },
    { name: t("halls_list_label"), href: INTERNAL_API.HALLS },
    { name: t(title), href: `${INTERNAL_API.HALLS}/${id}` },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    isWorking: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditView
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        breadcrumbs={breadcrumbs}
      >
        <FormData />
      </EditView>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");
  const { watch } = useFormContext();

  const lastModification = watch("lastModification");

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={8}>
            <CustomizedTextField label={t("device_new_name")} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CustomizedSwitch rightLabel={""} topLabel={t("halls_is_working")} name={"isWorking"} />
          </Grid>
        </Grid>
        {lastModification && (
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ fontStyle: "italic" }}>
              {lastModification}
            </Typography>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default AllDevicesNew;
