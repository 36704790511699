import React from "react";
import TableCell from "@mui/material/TableCell";
import { IMAGE_TYPES } from "enums/imageTypes";
import { Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";

const CellImageStatus = ({ data }) => {
  return (
    <TableCell>
      {data.type === IMAGE_TYPES.REJECTED ? (
        <Typography sx={{ color: color.red }}>{data.displayName}</Typography>
      ) : data.type === IMAGE_TYPES.ACCEPTED ? (
        <Typography sx={{ color: color.green }}>{data.displayName}</Typography>
      ) : (
        <Typography>{data.displayName}</Typography>
      )}
    </TableCell>
  );
};

export default CellImageStatus;
