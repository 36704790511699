import { Checkbox, Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DataTableHead } from "components/Table/DataTableHead";
import {
  clearAllFilters,
  fetchCrudList,
  selectListError,
  selectListFilters,
  selectListHeadCells,
  selectListInfo,
  selectListIsLoading,
  selectListItems,
  setListLimit,
  setListOrder,
  setListOrderBy,
  setListPage,
} from "redux/slices/crud";
import {
  selectListCloneDialogOpen,
  selectListDeleteDialogOpen,
  setCloneOrCopyButtonDisabled,
  setListCloneDialogOpen,
  setListDeleteDialogOpen,
} from "redux/slices/materials";
import { selectModulePath, setModulePath } from "redux/slices/menu";

import Error from "components/Error";
const ConfirmationDialog = React.lazy(() => import("components/ConfirmationDialog"));
const ImagePreviewDialog = React.lazy(() => import("components/ImagePreviewDialog"));
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import RowNoData from "components/Table/RowNoData";
import { useNavigate } from "react-router-dom";
import { HEAD_CELL_TYPES } from "enums/headCellTypes";
import CellActions from "components/Table/CellActions";
import CellActionDiff from "components/Table/CellActionDiff";
import CellMiniature from "components/Table/CellMiniature";
import CellImageStatus from "components/Table/CellImageStatus";
import CellPreviewDiff from "components/Table/CellPreviewDiff";
import Box from "@mui/material/Box";
import CellLink from "components/Table/CellLink";
import CellMaterials from "components/Table/CellMaterials";

const defaultAdditionalParameters = {
  textFieldRow: false,
  noCheckboxColumn: false,
  noPagination: false,
  getInvoiceAfterClick: false,
};

const DataTable = ({
  modulePath,
  endpoint,
  isClickable = true,
  additionalParameters = defaultAdditionalParameters,
}) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filters = useSelector(selectListFilters);
  const info = useSelector(selectListInfo);
  const headCells = useSelector(selectListHeadCells);
  const items = useSelector(selectListItems);
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { page, limit, order, orderBy } = filters;
  const { totalItems } = info;
  const { enqueueSnackbar } = useSnackbar();
  const savedModulePath = useSelector(selectModulePath);

  const [checkedCheckboxIdRow, setCheckedCheckboxIdRow] = useState([]);
  const [checkedCheckboxAll, setCheckedCheckboxAll] = useState(false);

  const deleteDialogOpen = useSelector(selectListDeleteDialogOpen);
  const cloneDialogOpen = useSelector(selectListCloneDialogOpen);

  const [imagePreviewDialogOpen, setImagePreviewDialogOpen] = useState(false);
  const [previewedImage, setPreviewedImage] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    dispatch(setListOrder(isAsc ? "desc" : "asc"));
    dispatch(setListOrderBy(property));
  };
  const handleChangePage = (event, newPage) => {
    dispatch(setListPage(newPage));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setListLimit(parseInt(event.target.value, 10)));
    dispatch(setListPage(0));
  };
  const handleClick = (e, row) => {
    if (isClickable) {
      navigate(modulePath + "/" + row.id);
    }
  };

  useEffect(() => {
    if (savedModulePath !== modulePath) {
      dispatch(setModulePath(modulePath));
      dispatch(clearAllFilters());
    }
  }, [modulePath]);

  useEffect(() => {
    if (savedModulePath === modulePath) {
      const promise = dispatch(fetchCrudList({ endpoint, filters }));

      return () => {
        promise.abort();
      };
    }
  }, [filters, modulePath]);

  useEffect(() => {
    if (items?.length === checkedCheckboxIdRow?.length && items?.length > 0) {
      setCheckedCheckboxAll(true);
    } else {
      setCheckedCheckboxAll(false);
    }

    if (checkedCheckboxIdRow.length > 0) {
      dispatch(setCloneOrCopyButtonDisabled(false));
    } else {
      dispatch(setCloneOrCopyButtonDisabled(true));
    }
  }, [checkedCheckboxIdRow, items]);

  const handleCheckAllCheckboxes = (e) => {
    const allCheckboxChecked = e.target.checked;
    if (allCheckboxChecked && items?.length > 0) {
      const tmpArray = [];

      for (const item of items) {
        tmpArray.push(item.id);
      }
      setCheckedCheckboxIdRow(tmpArray);
    } else {
      setCheckedCheckboxIdRow([]);
    }
  };

  const handleCheckOneCheckbox = (e, row) => {
    const checkboxChecked = e.target.checked;
    if (checkboxChecked) {
      setCheckedCheckboxIdRow((prevState) => [...prevState, row.id]);
    } else {
      setCheckedCheckboxIdRow((prevState) => {
        return prevState.filter((item) => {
          return item !== row.id;
        });
      });
    }
  };

  const handleDelete = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListDeleteDialogOpen(false));

    backend.post(endpoint + "deleteMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleImageAction = (row, action) => {
    const data = {
      id: row.id,
      type: action,
    };

    backend.post(endpoint + "type", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleClone = () => {
    const data = checkedCheckboxIdRow;
    dispatch(setListCloneDialogOpen(false));

    backend.post(endpoint + "cloneMany", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        setCheckedCheckboxIdRow([]);
        dispatch(fetchCrudList({ endpoint, filters }));
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleImagePreview = (image) => {
    setPreviewedImage(image);
    setImagePreviewDialogOpen(true);
  };

  const handleImagePreviewClosed = (isActionDone) => {
    if (isActionDone) {
      dispatch(fetchCrudList({ endpoint, filters }));
    }
    setPreviewedImage(null);
    setImagePreviewDialogOpen(false);
  };

  if (error.isError) {
    return <Error error={error} />;
  }
  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer>
            <Table aria-label="table">
              <DataTableHead
                headCells={headCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                noCheckbox={additionalParameters?.noCheckboxColumn}
                handleCheckAllCheckboxes={handleCheckAllCheckboxes}
                checkedCheckboxAll={checkedCheckboxAll}
              />
              <TableBody>
                {items?.length === 0 && (
                  <RowNoData
                    colSpan={additionalParameters?.noCheckboxColumn ? headCells?.length : headCells?.length + 1}
                  />
                )}
                {items.map((row, i) => {
                  return (
                    <Tooltip
                      key={`tt-${i}`}
                      title={
                        row["tooltipText"] == null ? (
                          ""
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: row["tooltipText"] }} />
                        )
                      }
                      placement="bottom"
                      followCursor={true}
                    >
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={additionalParameters?.noCheckboxColumn ? { height: 50 } : {}}
                      >
                        {!additionalParameters?.noCheckboxColumn && (
                          <TableCell key={i} align="left">
                            <Checkbox
                              color={"default"}
                              size={"small"}
                              onClick={(e) => handleCheckOneCheckbox(e, row)}
                              checked={checkedCheckboxIdRow.includes(row.id)}
                            />
                          </TableCell>
                        )}
                        {headCells.map((hc, i) => {
                          if (hc.type === HEAD_CELL_TYPES.ACTION) {
                            return <CellActions key={i} data={row[hc.id]} row={row} handleAction={handleImageAction} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.ACTION_DIFF) {
                            return <CellActionDiff key={i} data={row[hc.id]} row={row} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.DIFF_PREVIEW) {
                            return <CellPreviewDiff key={i} data={row[hc.id]} row={row} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.MINIATURE) {
                            return (
                              <CellMiniature key={i} data={row[hc.id]} row={row} handlePreview={handleImagePreview} />
                            );
                          }
                          if (hc.type === HEAD_CELL_TYPES.IMAGE_STATUS) {
                            return <CellImageStatus key={i} data={row[hc.id]} handlePreview={handleImagePreview} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.LINK) {
                            return <CellLink key={i} data={row[hc.id]} />;
                          }
                          if (hc.type === HEAD_CELL_TYPES.MATERIALS) {
                            return <CellMaterials key={i} data={row[hc.id]} />;
                          }
                          return (
                            <TableCell
                              key={i}
                              align={hc.numeric ? "right" : "left"}
                              onClick={(e) => handleClick(e, row)}
                              className={hc.deviceText ? "devices-font" : ""}
                              style={
                                hc.noSplitText
                                  ? { whiteSpace: "nowrap" }
                                  : { whiteSpace: "normal", wordBreak: "break-word" }
                              }
                              sx={hc.boldText ? { fontWeight: "bold" } : {}}
                            >
                              {!hc.bool ? (
                                row[hc.id]
                              ) : row[hc.id] ? (
                                <Checkbox size={"small"} checked disabled />
                              ) : (
                                <Checkbox size={"small"} disabled />
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {!additionalParameters?.noPagination && (
            <TablePagination
              rowsPerPageOptions={[15, 25, 50]}
              component="div"
              count={parseInt(totalItems)}
              rowsPerPage={limit}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("tp_rows_per_page")}
              labelDisplayedRows={({ from, to, count }) => {
                return "" + from + "-" + to + t("tp_from") + count;
              }}
            />
          )}
        </>
      )}
      {deleteDialogOpen && (
        <Suspense>
          <ConfirmationDialog
            open={deleteDialogOpen}
            close={() => dispatch(setListDeleteDialogOpen(false))}
            confirm={handleDelete}
          />
        </Suspense>
      )}
      {cloneDialogOpen && (
        <Suspense>
          <ConfirmationDialog
            open={cloneDialogOpen}
            close={() => dispatch(setListCloneDialogOpen(false))}
            confirm={handleClone}
            customTitle={"confirmation_modal_title_clone"}
          />
        </Suspense>
      )}
      {imagePreviewDialogOpen && (
        <Suspense>
          <ImagePreviewDialog data={previewedImage} open={imagePreviewDialogOpen} close={handleImagePreviewClosed} />
        </Suspense>
      )}
    </Box>
  );
};
export default DataTable;
