import React, { memo, useState } from "react";
import { Avatar } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import * as color from "assets/styles/jss/common/colors";

const placeholder = "/time.png";

const LiveImageView = ({ image, name }) => {
  const [img, setImg] = useState(image);
  const handleError = (e) => {
    e.target.error = null;
    setImg(placeholder);

    if (image !== null) {
      setTimeout(setOriginalImage, 12000, e);
    }
  };

  const setOriginalImage = () => {
    setImg(image);
  };

  return (
    <>
      <Avatar
        src={img}
        alt={name}
        sx={{ height: "460px", width: "auto", background: "black" }}
        variant="square"
        onError={handleError}
      >
        <ImageIcon sx={{ color: color.darkGray }} />
      </Avatar>
    </>
  );
};
export default memo(LiveImageView);
