import React from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { getT, MATERIAL_TYPE } from "enums/MATERIAL_TYPE";
import { Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";

const CellMaterials = ({ data }) => {
  const { t } = useTranslation("common");

  return (
    <TableCell>
      {!data ||
        (data.length === 0 && (
          <Typography variant="caption" component="span" style={{ whiteSpace: "nowrap" }}>
            {`${t(MATERIAL_TYPE.NOT_DETECT.t)}`}
          </Typography>
        ))}
      {data?.map((item) => (
        <>
          <Typography variant="caption" component="span" style={{ whiteSpace: "nowrap" }}>
            {t(getT(item.material))}
            <Box component="span" color={alpha(color.black, 0.6)}>{` (${item.probability})`}</Box>
          </Typography>
          <br />
        </>
      ))}
    </TableCell>
  );
};

export default CellMaterials;
