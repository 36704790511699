import { Button, Grid, Paper } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import EditView from "components/EditView";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.MY_PROFILE;
const title = "profile_edit_label";
const moduleUrl = INTERNAL_API.MY_PROFILE;

const UserEdit = () => {
  const { t } = useTranslation("common");
  const [changePassword, setChangePassword] = useState(false);

  const breadcrumbs = [
    { name: t("menu_settings"), href: "" },
    { name: t(title), href: INTERNAL_API.MY_PROFILE },
  ];

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    email: Yup.string().email(t("form-validation-mail")).max(255).required(t("form-validation-required")),
    changePassword: Yup.boolean(),
    password: Yup.string().when("changePassword", {
      is: true,
      then: (validationSchema) => validationSchema.trim().required(t("form-validation-required")),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    repeatedPassword: Yup.string().when("changePassword", {
      is: true,
      then: (validationSchema) =>
        validationSchema.test("passwords-match", t("form-validation-match-password"), function (value) {
          return this.parent.password === value;
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditView
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        noBackButton
        editWithoutId
        noDeleteButton
        breadcrumbs={breadcrumbs}
      >
        <FormData changePassword={changePassword} setChangePassword={setChangePassword} />
      </EditView>
    </>
  );
};

const FormData = ({ changePassword, setChangePassword }) => {
  const { register, formState, setValue } = useFormContext();
  const [roleTypes, setRoleTypes] = useState([]);

  useEffect(() => {
    backend.get(EXTERNAL_API.ROLES_ALL).then((res) => {
      if (isSuccess(res)) {
        setRoleTypes(res.data);
      }
    });
  }, []);

  const { t } = useTranslation("common");

  return (
    <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            disabled
            label={t("user_edit_login")}
            name={"login"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedSelect
            label={t("user_edit_role")}
            name={"roleId"}
            items={roleTypes}
            errors={formState?.errors}
            inputRef={register}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            label={t("user_edit_name")}
            name={"name"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomizedTextField
            label={t("user_edit_email")}
            name={"email"}
            inputRef={register}
            errors={formState?.errors}
          />
        </Grid>
        {!changePassword ? (
          <Grid item xs={12}>
            <Button
              variant={"contained"}
              onClick={() => {
                setChangePassword((prev) => !prev);
                setValue("changePassword", true);
              }}
            >
              {t("button_change_password")}
            </Button>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6}>
              <CustomizedTextField
                label={t("user_edit_password")}
                name={"password"}
                inputRef={register}
                errors={formState?.errors}
                type={"password"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomizedTextField
                label={t("user_edit_repeat_password")}
                name={"repeatedPassword"}
                inputRef={register}
                errors={formState?.errors}
                type={"password"}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default UserEdit;
