import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = ({isLogged}) => {
    return (
        <>
            {isLogged ? <Outlet/> : <Navigate to={"/login"}/>}
        </>
    );
}



