import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import React, { Fragment, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";

const CellPreviewDiff = ({ row, data }) => {
  const { t } = useTranslation("common");
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <TableCell>
        <Grid container spacing={1}>
          {data.changeTypeDisplayName && (
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600 }}>{data.changeTypeDisplayName}</Typography>
            </Grid>
          )}
          {data.diffs.map((diff, index) => (
            <Fragment key={diff.fieldName}>
              {(index < 3 || isExpanded) && (
                <>
                  <Grid item md={4} xs={12}>
                    <Tooltip title={diff.fieldName}>
                      <Typography>{diff.fieldDisplayName}</Typography>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={
                      diff.valueChanged
                        ? { backgroundColor: alpha(color.red, 0.13), borderRadius: "8px" }
                        : { backgroundColor: alpha(color.red, 0.04), borderRadius: "8px" }
                    }
                  >
                    {diff.valueBefore}
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={6}
                    sx={
                      diff.valueChanged
                        ? { backgroundColor: alpha(color.green, 0.13), borderRadius: "8px" }
                        : { backgroundColor: alpha(color.green, 0.04), borderRadius: "8px" }
                    }
                  >
                    {diff.valueAfter}
                  </Grid>
                </>
              )}
            </Fragment>
          ))}
          {data.diffs.length > 3 && (
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <IconButton onClick={() => setIsExpanded((prevState) => !prevState)}>
                  {isExpanded ? <ExpandLessIcon /> : <MoreHorizIcon />}
                </IconButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </TableCell>
    </>
  );
};

export default CellPreviewDiff;
