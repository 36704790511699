import { useState } from "react";
import { Grid, LinearProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useEffect } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useParams } from "react-router-dom";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.DEVICE_STATUS;
const AllDevicesDetailSummary = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();

  const [summary, setSummary] = useState(null);
  const [isGetDataProgress, setIsGetDataProgress] = useState(true);

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        setSummary(response.data);
      } else {
        console.error(response);
      }
      setIsGetDataProgress(false);
    });
  }, []);

  return (
    <Grid container spacing={5}>
      {isGetDataProgress ? (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant={"h5"}>{t("device_details_today")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label="customized table" sx={{ width: "100%" }}>
                    <TableBody>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold", width: "40%" }}>
                          {t("device_details_power")}
                        </TableCell>
                        <TableCell>
                          {summary.tegra === null
                            ? t("detail_no_data")
                            : summary.tegra === "on"
                            ? t("detail_on")
                            : summary.tegra === "off"
                            ? t("detail_off")
                            : t("detail_wrong_data")}
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_detection")}
                        </TableCell>
                        <TableCell>
                          {summary.status === null
                            ? t("detail_no_data")
                            : summary.status === "on"
                            ? t("detail_on")
                            : summary.status === "off"
                            ? t("detail_off")
                            : t("detail_wrong_data")}
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_reject_signal")}
                        </TableCell>
                        <TableCell>
                          {summary.isRecoil === null
                            ? t("detail_no_data")
                            : summary.isRecoil
                            ? t("detail_on")
                            : t("detail_off")}
                        </TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_lamp_status")}
                        </TableCell>
                        <TableCell>Ok</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_number_cycles")}
                        </TableCell>
                        <TableCell>{summary.cycle == null ? t("detail_no_data") : summary.cycle}</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_number_of_detections")}
                        </TableCell>
                        <TableCell>{summary.recoilToday == null ? t("detail_no_data") : summary.recoilToday}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant={"h5"}>{t("device_details_summary")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label="customized table" sx={{ width: "100%" }}>
                    <TableBody>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold", width: "60%" }}>
                          {t("device_details_total_number_of_cycles")}
                        </TableCell>
                        <TableCell>{summary.allCycle == null ? t("detail_no_data") : summary.allCycle}</TableCell>
                      </TableRow>
                      <TableRow hover>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          {t("device_details_total_number_of_discoveries")}
                        </TableCell>
                        <TableCell>{summary.allRecoil == null ? t("detail_no_data") : summary.allRecoil}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default AllDevicesDetailSummary;
