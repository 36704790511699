import makeStyles from "@mui/styles/makeStyles";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUserLogged } from "redux/slices/user";
import { Avatar, Box, Menu } from "@mui/material";
import React from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MenuItem from "@mui/material/MenuItem";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

const ProfileInfo = () => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
        marginRight: 0,
        cursor: "pointer",
        fontWeight: "normal",
      },
      children: name.length > 0 ? name[0].toUpperCase() : "?",
    };
  }

  const handleLogout = (popupState) => {
    popupState.close();

    backend
      .post("/user/logout")
      .then((response) => {
        if (isSuccess(response) && response.data === "OK") {
          dispatch(setUserLogged(false));
          enqueueSnackbar("Wylogowano poprawnie", { variant: "success" });
        } else {
          enqueueSnackbar("Błąd wylogowania", { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.profileInfo}>
      <Box className={classes.user}>
        <PopupState variant="popover" popupId="demoMenu">
          {(popupState) => (
            <>
              <Avatar
                {...stringAvatar(user?.userName ? user?.userName : "")}
                alt={user?.login}
                {...bindTrigger(popupState)}
              />
              <Menu {...bindMenu(popupState)}>
                <MenuItem
                  onClick={() => {
                    handleLogout(popupState);
                  }}
                >
                  {t("login_logout")}
                </MenuItem>
              </Menu>
            </>
          )}
        </PopupState>
      </Box>
    </div>
  );
};
export default ProfileInfo;
