import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Stack, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { REPORT_TYPES } from "enums/REPORT_TYPES";
import { subDays } from "date-fns";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";

const HeaderBuilderReports = ({ type, setDate, csvFunction, pdfFunction }) => {
  const { t } = useTranslation("common");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (type === REPORT_TYPES.DAILY) {
      setFrom(new Date());
    } else {
      setFrom(subDays(new Date(), 7));
      setTo(new Date());
    }
  }, [type]);

  useEffect(() => {
    if (type === REPORT_TYPES.DAILY && from != null) {
      setDate({ date: from });
    } else if (type !== REPORT_TYPES.DAILY && from != null && to != null) {
      setDate({ from: from, to: to });
    }
  }, [from, to]);

  const handleSetFrom = (from) => {
    setFrom(from);
  };
  const handleSetTo = (to) => {
    setTo(to);
  };

  return (
    <Toolbar>
      <Grid container spacing={5}>
        <Grid item xs={5} sm={4} md={3}>
          <DatePicker
            label={type === REPORT_TYPES.PERIODIC ? t("reports_filter_from") : t("reports_filter_date")}
            value={from}
            onChange={handleSetFrom}
            renderInput={(params) => (
              <TextField {...params} fullWidth variant="outlined" size="small" placeholder={t("browser_filter_from")} />
            )}
            inputFormat={"dd-MM-yyyy"}
          />
        </Grid>
        {type === REPORT_TYPES.PERIODIC && (
          <Grid item xs={5} sm={4} md={3}>
            <DatePicker
              label={t("reports_filter_to")}
              value={to}
              onChange={handleSetTo}
              renderInput={(params) => <TextField fullWidth {...params} variant="outlined" size="small" />}
              inputFormat={"dd-MM-yyyy"}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Tooltip title="get PDF">
              <IconButton onClick={pdfFunction}>
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="get CSV">
              <IconButton onClick={csvFunction}>
                <ArticleIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default HeaderBuilderReports;
