import React, { useEffect, useState } from "react";
import LiveDetectionsBox from "modules/LiveDevice/LiveDeviceComponent/LiveDetectionsBox";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { EVENT_TYPE } from "../../../enums/EVENT_TYPE";
import { useTranslation } from "react-i18next";

const LiveDetectionsView = ({ data }) => {
  const { t } = useTranslation("common");
  const newCycle = data?.status?.cycle;
  const newRecoil = data?.image?.image;

  const [list, setList] = useState(new Array(20).fill({ value: EVENT_TYPE.EMPTY, date: "" }));

  const [recoilToday, setRecoilToday] = useState(null);
  const [cycleToday, setCycleToday] = useState(null);
  const [lastCycleChangedTime, setLastCycleChangedTime] = useState(null);
  const [firstRenderTime, setFirstRenderTime] = useState(null);

  const isOneRow = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(() => {
    const now = new Date();

    setRecoilToday(newRecoil);
    setCycleToday(newCycle);
    setFirstRenderTime(now);
  }, []);

  useEffect(() => {
    const now = new Date();

    let cycleChanged = false;

    if (cycleToday !== null) {
      cycleChanged = cycleToday !== newCycle;

      if (cycleChanged) {
        setLastCycleChangedTime(now);
      } else if (lastCycleChangedTime != null) {
        const diffDate = Math.abs(lastCycleChangedTime - now);
        if (diffDate < 90000) {
          cycleChanged = true;
        }
      }
    }

    const recoilsChanged = recoilToday !== null && recoilToday !== newRecoil;

    let event = EVENT_TYPE.NO_WORK;
    if (recoilsChanged) {
      event = EVENT_TYPE.RECOIL;
    } else if (cycleChanged) {
      event = EVENT_TYPE.CYCLE;
    }
    //fix first rerender
    if (firstRenderTime != null && Math.abs(firstRenderTime - now) > 1000) {
      addEvent(event, now.toLocaleTimeString());
    }

    setRecoilToday(newRecoil);
    setCycleToday(newCycle);
  }, [data]);

  const addEvent = (type, date) => {
    setList((prevState) => {
      let tmp = [...prevState];
      tmp.pop();
      tmp.unshift({ value: type, date: date });

      return tmp;
    });
  };

  return (
    <Stack direction="column" alignItems="left" spacing={2}>
      <Typography>{t("device_live_last_detections")}</Typography>
      {isOneRow ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {list.map((item, idx) => (
            <LiveDetectionsBox key={idx} value={item.value} date={item.date} />
          ))}
        </Stack>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {list.map((item, idx) => idx < 10 && <LiveDetectionsBox key={idx} value={item.value} date={item.date} />)}
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            {list.map((item, idx) => idx >= 10 && <LiveDetectionsBox key={idx} value={item.value} date={item.date} />)}
          </Stack>
        </>
      )}
    </Stack>
  );
};
export default LiveDetectionsView;
