import { Grid, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import EditView from "components/EditView";
import { useEffect, useRef, useState } from "react";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { ipv4 } from "utils/ipv4validation";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.DEVICES;
const title = "devices_label_new";
const moduleUrl = INTERNAL_API.DEVICE_SETTINGS;

const AllDevicesNew = () => {
  const { t } = useTranslation("common");

  const breadcrumbs = [
    { name: t("menu_devices"), href: "" },
    { name: t("devices_label"), href: INTERNAL_API.DEVICE },
    { name: t(title), href: INTERNAL_API.DEVICE_SETTINGS + "/new" },
  ];

  Yup.addMethod(Yup.string, "ipv4", ipv4);
  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    ip: Yup.string().ipv4().trim().required(t("form-validation-required")),
    hall: Yup.string().trim().required(t("form-validation-required")),
  });

  const refForm = useRef(null);

  return (
    <>
      <EditView
        endpoint={endpoint}
        title={title}
        moduleUrl={moduleUrl}
        validationSchema={validationSchema}
        refForm={refForm}
        newElement
        breadcrumbs={breadcrumbs}
      >
        <FormData />
      </EditView>
    </>
  );
};

const FormData = () => {
  const { t } = useTranslation("common");
  const [halls, setHalls] = useState("");

  useEffect(() => {
    backend.get(EXTERNAL_API.HALLS_ALL).then((res) => {
      if (isSuccess(res)) {
        setHalls(
          res.data.map((item) => {
            return {
              id: item.id,
              displayName: item.name,
            };
          }),
        );
      }
    });
  }, []);

  return (
    <>
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("device_new_name")} name={"name"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedTextField label={t("device_new_ip")} name={"ip"} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomizedSelect label={t("device_new_hall")} name={"hall"} items={halls} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AllDevicesNew;
