import { Grid } from "@mui/material";
import { Suspense, useState, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import * as color from "assets/styles/jss/common/colors";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
import OperationBox from "modules/Devices/AllDevicesEdit/OperationBox";

const endpoint = EXTERNAL_API.SETTINGS_LIVE;

const iconSx = {
  fontSize: "3rem",
  color: color.green + "!important",
};

const ConfirmationDialog = lazy(() => import("components/ConfirmationDialog"));

const AllDevicesEditLiveOperation = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenedDialogResetDevice, setIsOpenedDialogResetDevice] = useState(false);
  const [isOpenedDialogResetApp, setIsOpenedDialogResetApp] = useState(false);
  const [isOpenedDialogNormalization, setIsOpenedDialogNormalization] = useState(false);

  const handleConfirmAction = (action) => {
    backend.get(`${endpoint}${action}/${id}`).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("device_edit_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("device_edit_status_error"), {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Grid item sx={{ marginRight: "15px" }}>
        <OperationBox
          onClick={() => setIsOpenedDialogResetDevice(true)}
          label={t("device_edit_button_restart_device")}
          icon={<PowerSettingsNewIcon sx={iconSx} />}
        />
      </Grid>
      <Grid item sx={{ marginRight: "15px" }}>
        <OperationBox
          onClick={() => setIsOpenedDialogResetApp(true)}
          label={t("device_edit_button_restart_application")}
          icon={<RestartAltIcon sx={iconSx} />}
        />
      </Grid>
      <Grid item sx={{ marginRight: "15px" }}>
        <OperationBox
          onClick={() => setIsOpenedDialogNormalization(true)}
          label={t("device_edit_button_normalize")}
          icon={<LineAxisIcon sx={iconSx} />}
        />
      </Grid>
      {isOpenedDialogResetDevice && (
        <Suspense>
          <ConfirmationDialog
            customTitle={t("device_edit_perform_operation_reboot_device")}
            open={isOpenedDialogResetDevice}
            close={() => setIsOpenedDialogResetDevice(false)}
            confirm={() => {
              setIsOpenedDialogResetDevice(false);
              handleConfirmAction("reboot-device");
            }}
          />
        </Suspense>
      )}
      {isOpenedDialogResetApp && (
        <Suspense>
          <ConfirmationDialog
            customTitle={t("device_edit_perform_operation_reboot_app")}
            open={isOpenedDialogResetApp}
            close={() => setIsOpenedDialogResetApp(false)}
            confirm={() => {
              setIsOpenedDialogResetApp(false);
              handleConfirmAction("reboot-app");
            }}
          />
        </Suspense>
      )}
      {isOpenedDialogNormalization && (
        <Suspense>
          <ConfirmationDialog
            customTitle={t("device_edit_perform_operation_normalization")}
            open={isOpenedDialogNormalization}
            close={() => setIsOpenedDialogNormalization(false)}
            confirm={() => {
              setIsOpenedDialogNormalization(false);
              handleConfirmAction("normalization");
            }}
          />
        </Suspense>
      )}
    </>
  );
};
export default AllDevicesEditLiveOperation;
