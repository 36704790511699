export function findMenu(menuItems) {
  const documentUrl = document.URL;

  if (menuItems?.length > 0 && documentUrl) {
    for (let i = 0; i < menuItems.length; i++) {
      for (const gr of menuItems[i].group) {
        for (const page of gr.pages) {
          if (documentUrl.includes(page.path)) {
            return { levelZero: i, levelOne: page.path };
          }
        }
      }
    }
  }
  return { levelZero: 0, levelOne: "" };
}
