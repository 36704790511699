import { Grid, LinearProgress, Paper } from "@mui/material";
import ReportTableDetectionOff from "modules/Reports/components/ReportTableDetectionOff";
import ReportTableDetectionOn from "modules/Reports/components/ReportTableDetectionOn";
import RadialChartDeviceDetectionOnOff from "modules/Reports/components/RadialChartDeviceDetectionOnOff";
import RadialChartCycleOnOff from "modules/Reports/components/RadialChartCycleOnOff";
import ChartDeviceDetectionOnOff from "modules/Reports/components/ChartDeviceDetectionOnOff";
import * as color from "assets/styles/jss/common/colors";

const ReportBody = ({ data }) => {
  return (
    <Grid container spacing={5} direction="row" justifyContent="center" alignItems="center">
      {data ? (
        <>
          <Grid item xs={12}>
            <Paper
              elevation={8}
              sx={{
                p: 10,
                mb: 10,
                border: 1,
                background: color.submenuBackgroundHover,
                borderColor: color.menuSelected,
              }}
            >
              <Grid container>
                <Grid item lg={0} xl={1} />
                <Grid item lg={6} xl={4}>
                  <RadialChartCycleOnOff items={data?.chartCyclesDetectionOnOff} />
                </Grid>
                <Grid item lg={0} xl={1} />
                <Grid item lg={6} xl={4}>
                  <RadialChartDeviceDetectionOnOff items={data?.chartDevicesDetectionOnOff} />
                </Grid>
                <Grid item lg={0} xl={1} />
                <Grid item xs={12}>
                  <ChartDeviceDetectionOnOff items={data?.chartDetectionOnOffPerDevices} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
              <ReportTableDetectionOn items={data?.reportItemsDetectionOn} />
            </Paper>
          </Grid>
          <Grid item lg={12} xl={6}>
            <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
              <ReportTableDetectionOff items={data?.reportItemsDetectionOff} />
            </Paper>
          </Grid>
          <Grid item lg={0} xl={6} />
        </>
      ) : (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
    </Grid>
  );
};
export default ReportBody;
