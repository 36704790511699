import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import Chart from "react-apexcharts";
import { labelsRadialChartCycleOnOff } from "modules/Reports/enum/chartLabels";
import chartColors from "modules/Reports/enum/chartColors";
import { color } from "@mui/system";

const LiveCyclesView = ({ withDetection, withoutDetection }) => {
  const { t } = useTranslation("common");

  const options = {
    series: [withDetection, withoutDetection],
    options: {
      colors: chartColors,
      labels: labelsRadialChartCycleOnOff,
      chart: {
        id: "radialChartCycleOnOff",
        type: "donut",
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        show: true,
        flow: true,
      },
      title: {
        text: t("device_apexcharts_title"),
        align: "center",
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: color.black,
        },
      },
    },
  };

  return withDetection > 0 || withoutDetection > 0 ? (
    <Chart options={options.options} series={options.series} type="donut" width="350" />
  ) : (
    <></>
  );
};
export default memo(LiveCyclesView);
