import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import ChipStatus from "modules/Live/Live/ChipStatus";
const DevicesStatisticTableCells = ({ cycle, recoilToday, time, restarts, status, tegra, isRecoil }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <TableCell align="left" sx={{ paddingX: 2 }}>
        <Typography>{cycle ? cycle : "0"}</Typography>
      </TableCell>
      <TableCell align="right" sx={{ paddingX: 2, width: "7%" }}>
        {recoilToday ? recoilToday : "0"}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap", paddingX: 2 }} align="center">
        {time ? time : t("table_no_data")}
      </TableCell>
      <TableCell sx={{ paddingX: 2 }} align="center">
        {<ChipStatus value={isRecoil} />}
      </TableCell>
      <TableCell sx={{ paddingX: 2 }} align="center">
        {<ChipStatus value={tegra === "on"} />}
      </TableCell>
      <TableCell sx={{ paddingX: 2 }} align="center">
        {<ChipStatus value={status === "on"} />}
      </TableCell>
      <TableCell sx={{ paddingX: 2 }} align="right">
        {restarts ? restarts : "0"}
      </TableCell>
    </>
  );
};
export default memo(DevicesStatisticTableCells);
