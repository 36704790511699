import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import DataTable from "components/Table/DataTable";
import HeaderBuilderBrowser from "layout/Header/HeaderBuilderBrowser";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const ActivityList = () => {
  const { t } = useTranslation("common");

  const breadcrumbs = [
    { name: t("menu_settings"), href: "" },
    { name: t("settings_activity_label"), href: INTERNAL_API.ACTIVITY },
  ];

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      header={t("settings_activity_label")}
      top={<HeaderBuilderBrowser dateFieldName="date" searchFieldName="changer" />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <DataTable
          modulePath={INTERNAL_API.ACTIVITY}
          endpoint={EXTERNAL_API.ACTIVITY}
          isClickable={false}
          additionalParameters={{ noCheckboxColumn: true }}
        />
      </Paper>
    </Layout>
  );
};
export default ActivityList;
