import { Button, Grid, InputLabel } from "@mui/material";
import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.SETTINGS_FLAP_TEST;

const ConfirmationDialog = React.lazy(() => import("components/ConfirmationDialog"));
const AllDevicesEditFlapTest = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenedDialog, setIsOpenedDialog] = useState(false);

  const validationSchema = Yup.object({
    cycles: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
  });

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const cycles = methods.watch("cycles");

  const onSubmit = () => {
    setIsOpenedDialog(true);
  };

  const handleConfirmAction = () => {
    backend.get(`${endpoint}${cycles}/${id}`).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("device_edit_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("device_edit_status_error"), {
          variant: "error",
        });
      }
    });
  };

  return (
    <Grid item xs={12}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={9}>
              <CustomizedTextField
                name="cycles"
                label={t("device_edit_number_of_cycles")}
                type="number"
                defaultValue={"1"}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>&nbsp;</InputLabel>
              <Button type="submit" variant="contained" size="small">
                {t("device_edit_button_start")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {isOpenedDialog && (
        <Suspense>
          <ConfirmationDialog
            customTitle={t("device_edit_test_flap_cycles")}
            open={isOpenedDialog}
            close={() => setIsOpenedDialog(false)}
            confirm={() => {
              setIsOpenedDialog(false);
              handleConfirmAction();
            }}
          />
        </Suspense>
      )}
    </Grid>
  );
};
export default AllDevicesEditFlapTest;
