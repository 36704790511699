import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import LiveTitle from "layout/Header/LiveTitle";
import { INTERNAL_API } from "../../enums/INTERNAL_API";
import { EXTERNAL_API } from "../../enums/EXTERNAL_API";
import HeaderBreadcrumbs from "layout/Header/HeaderBreadcrumbs";
import { useLocation, useParams } from "react-router-dom";
import FullScreenButton from "modules/LiveDevice/LiveDeviceComponent/FullScreenButton";
import Container from "@mui/material/Container";
import { setLiveCommunicationOk } from "redux/slices/materials";
import { useDispatch } from "react-redux";
import LiveDeviceView from "modules/LiveDevice/LiveDeviceComponent/LiveDeviceView";
import { reactLocalStorage } from "reactjs-localstorage";
import { LOCAL_STORAGE_NAMES } from "../../enums/LOCAL_STORAGE_NAMES";

const LiveDevicePage = () => {
  reactLocalStorage.set(LOCAL_STORAGE_NAMES.LAST_LOCATION, useLocation()?.pathname);
  const { t } = useTranslation("common");
  const [firstLoadProgress, setFirstLoadProgress] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const breadcrumbs = [
    { name: t("menu_overview"), href: "" },
    { name: t("live_label"), href: INTERNAL_API.LIVE },
    {
      name: t("device_live_label"),
      href: `${INTERNAL_API.LIVE_DEVICE}/${uuid}`,
    },
  ];

  const refreshData = () => {
    backend
      .get(`${EXTERNAL_API.LIVE_DEVICE}${uuid}`)
      .then((res) => {
        if (isSuccess(res)) {
          setData(res.data);
          dispatch(setLiveCommunicationOk(true));
          setError(false);
        } else {
          dispatch(setLiveCommunicationOk(false));
        }
      })
      .catch(() => {
        dispatch(setLiveCommunicationOk(false));
      })
      .finally(() => setFirstLoadProgress(false));
  };

  useEffect(() => {
    const getFirstData = () => {
      backend
        .get(`${EXTERNAL_API.LIVE_DEVICE}${uuid}`)
        .then((res) => {
          if (isSuccess(res)) {
            setData(res.data);
            dispatch(setLiveCommunicationOk(true));
          } else {
            setError(`Error ${res.status} ${res.statusText}`);
          }
        })
        .catch(() => {
          setError(`Error`);
        })
        .finally(() => setFirstLoadProgress(false));
    };

    getFirstData();

    const interval = setInterval(() => {
      refreshData();
    }, 12000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container maxWidth={"md"} sx={{ paddingY: { xs: "0", md: "1rem" } }}>
      <Typography variant="h1">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          {!isFullScreen ? (
            <>
              <LiveTitle
                title={
                  data ? (
                    <>
                      {t("device_live_label")}&nbsp;
                      <Box component={"span"} className={"devices-font"}>
                        {data.name}
                      </Box>
                    </>
                  ) : (
                    t("device_live_label")
                  )
                }
              />
              <FullScreenButton isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />
            </>
          ) : (
            <>
              <Typography>Last detection: {data?.image.time}</Typography>
              <FullScreenButton isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} />
            </>
          )}
        </Stack>
      </Typography>
      {!isFullScreen && (
        <Box className={"fcx-mb-10"}>
          <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />
        </Box>
      )}
      {firstLoadProgress ? (
        <LinearProgress />
      ) : error ? (
        <Typography variant="h2" sx={{ py: "1rem" }}>
          {error}
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {!isFullScreen && (
            <Grid item xs={12}>
              Last detection: {data?.image.time}
            </Grid>
          )}
          <LiveDeviceView data={data} />
        </Grid>
      )}
    </Container>
  );
};
export default LiveDevicePage;
