import Layout from "layout/Layout";
import { useTranslation } from "react-i18next";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { useSnackbar } from "notistack";

const ScenariosPage = ({ type }) => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();

  const title = t("validation_scenarios_label");

  const breadcrumbs = [
    { name: t("menu_reports"), href: "" },
    { name: title, href: INTERNAL_API.VALIDATION_SCENARIOS },
  ];

  return <Layout top={<></>} header={title} breadcrumbs={breadcrumbs}></Layout>;
};
export default ScenariosPage;
