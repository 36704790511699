import Chart from "react-apexcharts";
import * as color from "assets/styles/jss/common/colors";
const MiniChart = ({
  x = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Today"],
  y = [30, 40, 35, 50, 49, 60, 70],
}) => {
  const options = {
    chart: {
      type: "area",
      height: 160,
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      categories: x,
    },
    colors: [color.menuBackground],
  };

  const series = [
    {
      name: "cycle",
      data: y,
    },
  ];

  return <Chart options={options} series={series} type="area" width={60} height={40} />;
};
export default MiniChart;
