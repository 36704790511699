import React from "react";
import AllDevicesEditLiveOperation from "modules/Devices/AllDevicesEdit/AllDevicesEditLiveOperation";
import AllDevicesEditFlapTest from "modules/Devices/AllDevicesEdit/AllDevicesEditFlapTest";
import { Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
const AllDevicesOperations = () => {
  const { t } = useTranslation("common");

  return (
    <Grid container spacing={0}>
      <AllDevicesEditLiveOperation />
      <Grid item xs={12} sx={{ marginY: 4 }}>
        <Divider />
        <Typography variant="h4">{t("device_edit_flap_test")}</Typography>
      </Grid>
      <AllDevicesEditFlapTest />
    </Grid>
  );
};
export default AllDevicesOperations;
