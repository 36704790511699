const layoutStyle = {
  layoutContainer: {
    display: "flex",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 375px)",
    "@media (max-width: 960px)": {
      width: "100%",
    },
  },
  main: {
    flexGrow: 1,
    padding: "0 4vh 4vh 4vh",
    "@media (max-width: 960px)": {
      padding: "0 2vh 4vh 2vh",
    },
  },
  drawerModal: {
    zIndex: "99999 !important",
    top: "65px !important",
  },
  iconDrawerClose: {
    textAlign: "right",
  },
  mobileMenu: {
    position: "absolute",
    top: "60px !important",
    width: "100%",
  },
};

export default layoutStyle;
