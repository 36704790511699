import Layout from "layout/Layout";
import { useTranslation } from "react-i18next";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { useSnackbar } from "notistack";
import { Box, Grid, Paper } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import StartBox from "modules/Validation/components/StartBox";
import React, { Suspense, useEffect, useRef, useState } from "react";
import backend from "api/backend";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import ResultRecoilValidation from "modules/Validation/components/ResultRecoilValidation";
const ConfirmationDialog = React.lazy(() => import("components/ConfirmationDialog"));

const detectionSignals = [
  { id: "1", displayName: "Only flash" },
  { id: "2", displayName: "Only sound" },
  { id: "3", displayName: "Flash and sound" },
];

const RecoilValidationPage = () => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const [devices, setDevices] = useState([]);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);

  const refForm = useRef(null);

  const title = t("validation_rejection_label");

  const breadcrumbs = [
    { name: t("menu_reports"), href: "" },
    { name: title, href: INTERNAL_API.VALIDATION_RECOIL },
  ];

  const validationSchema = Yup.object({
    device: Yup.string().trim().required(t("form-validation-required")),
    detectionSignal: Yup.string().trim().required(t("form-validation-required")),
    cycles: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
    pauses: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
  });

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    backend.get(EXTERNAL_API.DEVICES_ALL).then((res) => {
      if (isSuccess(res)) {
        setDevices(res.data.map((dev) => ({ id: dev.id, displayName: dev.name })));

        if (res.data.length > 0) {
          methods.setValue("device", res.data[0].id);
        }
      }
    });
  }, []);

  const handleStart = () => {
    if (refForm.current != null) {
      refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
    }
  };

  const onSubmit = () => {
    setIsOpenedDialog(true);
  };

  const handleConfirmAction = () => {
    console.log("test example");
  };

  return (
    <Layout header={title} breadcrumbs={breadcrumbs}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Paper
            elevation={8}
            sx={{ p: 10, mb: 10, border: 1, background: color.submenuBackgroundHover, borderColor: color.menuSelected }}
          >
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)} ref={refForm}>
                <Grid container spacing={5}>
                  <Grid item xs={2} sx={{ minWidth: "135px" }}>
                    <StartBox onClick={handleStart} label={t("validation_start")} />
                  </Grid>
                  <Grid item xs={10}>
                    <Box>
                      <Grid container spacing={5}>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            label={t("validation_device")}
                            name={"device"}
                            items={devices}
                            noNullValue
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedSelect
                            label={t("validation_signal_validation")}
                            name={"detectionSignal"}
                            items={detectionSignals}
                            defaultValue="3"
                            noNullValue
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedTextField
                            className={"no-arrow-number-input"}
                            name="cycles"
                            label={t("device_edit_number_of_cycles")}
                            type="number"
                            defaultValue={"1"}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <CustomizedTextField
                            className={"no-arrow-number-input"}
                            name="pauses"
                            label={t("validation_number_of_pauses")}
                            type="number"
                            defaultValue={"1"}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ResultRecoilValidation />
        </Grid>
      </Grid>
      {isOpenedDialog && (
        <Suspense>
          <ConfirmationDialog
            customTitle={t("validation_detection_validation_task")}
            open={isOpenedDialog}
            close={() => setIsOpenedDialog(false)}
            confirm={() => {
              setIsOpenedDialog(false);
              handleConfirmAction();
            }}
          />
        </Suspense>
      )}
    </Layout>
  );
};
export default RecoilValidationPage;
