import Chart from "react-apexcharts";
import * as color from "assets/styles/jss/common/colors";
import chartColors from "modules/Reports/enum/chartColors";
import { labelsRadialChartDeviceDetectionOnOff } from "modules/Reports/enum/chartLabels";

const RadialChartDeviceDetectionOnOff = ({ items }) => {
  const options = {
    series: items?.series,
    options: {
      colors: chartColors,
      labels: labelsRadialChartDeviceDetectionOnOff,
      chart: {
        id: "radialChartDeviceDetectionOnOff",
        type: "donut",
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
      },
      plotOptions: {
        pie: {
          customScale: 1,
        },
      },
      noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      title: {
        text: "There are many variations of passages...",
        align: "left",
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: color.black,
        },
      },
      legend: {
        position: "right",
        horizontalAlign: "center",
        show: true,
        flow: true,
      },
    },
  };

  return <Chart options={options.options} series={options.series} type="donut" width="350" />;
};
export default RadialChartDeviceDetectionOnOff;
