import { Button, Grid, InputLabel, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm } from "react-hook-form";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.SETTINGS_X_RAY;

const AllDevicesEditRtgSettings = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    kvVal: Yup.number()
      .typeError(t("form-validation-number"))
      .min(130)
      .max(205)
      .required(t("form-validation-required")),
    maVal: Yup.number()
      .typeError(t("form-validation-number"))
      .min(100)
      .max(250)
      .required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data;

        for (const dataKey in item) {
          setValue(dataKey, item[dataKey]);
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  const { register, handleSubmit, setValue, formState, watch } = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    backend.post(endpoint + id, formData).then((res) => {
      if (isSuccess(res)) {
        setValue("lastModification", res.data["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const lastModification = watch("lastModification");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <InputLabel htmlFor="kVset">kVset</InputLabel>
          <TextField
            {...register("kvVal")}
            helperText={formState?.errors["kvVal"] ? formState?.errors["kvVal"].message : ""}
            error={!!formState?.errors["kvVal"]}
            variant="outlined"
            size="small"
            fullWidth
            type={"number"}
            inputProps={{ min: 130, max: 205, step: 1 }}
            defaultValue={"160"}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="mAset">mAset</InputLabel>
          <TextField
            {...register("maVal")}
            helperText={formState?.errors["maVal"] ? formState?.errors["maVal"].message : ""}
            error={!!formState?.errors["maVal"]}
            variant="outlined"
            size="small"
            fullWidth
            type={"number"}
            inputProps={{ min: 100, max: 250, step: 1 }}
            defaultValue={""}
          />
        </Grid>
        {lastModification && (
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ fontStyle: "italic" }}>
              {lastModification}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button variant="contained" size="small" type="submit">
            {t("button_save")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default AllDevicesEditRtgSettings;
