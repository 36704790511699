import { yupResolver } from "@hookform/resolvers/yup";
import Layout from "layout/Layout";
import { useSnackbar } from "notistack";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchCrudItem, selectListError, selectListIsLoading } from "redux/slices/crud";
import backend from "api/backend";
import { clearCurrentItem, fetchCrudList, selectEditItem, selectListFilters, setListPage } from "redux/slices/crud";
import { isSuccess } from "utils/http";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import EditActionButtons from "components/Edit/EditActionButtons";
import CircularProgress from "@mui/material/CircularProgress";
const ConfirmationDialog = React.lazy(() => import("components/ConfirmationDialog"));
import Error from "components/Error";

const EditView = ({
  children,
  endpoint,
  title,
  moduleUrl,
  validationSchema,
  onSubmit,
  refForm = useRef(null),
  noDeleteButton,
  noBackButton,
  customSaveFunc = null,
  newElement,
  editWithoutId,
  breadcrumbs,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectListIsLoading);
  const error = useSelector(selectListError);
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const filters = useSelector(selectListFilters);
  const [blockSetFields, setBlockSetFields] = useState(true);
  const [ignoreError, setIgnoreError] = useState(false);

  const [openedRemoveDialog, setOpenedRemoveDialog] = useState(false);

  const current = useSelector(selectEditItem);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  }); // initialize the hook

  useEffect(() => {
    dispatch(clearCurrentItem());
    if (id === "new" || newElement === true) {
      setIgnoreError(true);
    } else {
      const fetchId = editWithoutId ? "" : id;
      dispatch(fetchCrudItem({ endpoint, id: fetchId }));
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, []);

  useEffect(() => {
    if (current && !blockSetFields) {
      Object.keys(current).forEach((k) => {
        methods.setValue(k, current[k]);
      });
    }
  }, [current]);

  useEffect(() => {
    if (id !== "new" && newElement !== true) {
      setBlockSetFields(false);
      setIgnoreError(false);
    }
  }, [id]);

  const handleSave = () => {
    console.log("Save");
    refForm.current.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
  };

  const handleSubmit = (d) => {
    console.log(d);

    if (typeof onSubmit === "function") {
      const data = onSubmit(d);
      console.log(data);
      _onSubmit(data);
    } else {
      _onSubmit(d);
    }
  };

  const _onSubmit = (d) => {
    let idTmp = id;

    if (id === "new" || newElement || current == null) {
      idTmp = 0;
    } else if (editWithoutId) {
      idTmp = "";
    }
    backend.post(endpoint + idTmp, d).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        if (res.data.item != null) {
          if (idTmp === 0) {
            const newId = res.data.item.id;
            dispatch(fetchCrudItem({ endpoint, id: newId }));
            navigate(moduleUrl + "/" + newId);
          } else {
            dispatch(fetchCrudItem({ endpoint, id: idTmp }));
          }
        } else {
          navigate(moduleUrl);
        }
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    setOpenedRemoveDialog(true);
  };

  const handleDeleteFromDB = () => {
    console.log("Delete");

    backend.delete(endpoint + id).then((res) => deleteResponseFunction(res));

    setOpenedRemoveDialog(false);
  };

  const deleteResponseFunction = (res) => {
    if (isSuccess(res)) {
      enqueueSnackbar(t("common_status_successfully"), {
        variant: "success",
      });
      dispatch(setListPage(0));
      dispatch(fetchCrudList({ endpoint, filters }));
      navigate(moduleUrl);
    } else {
      enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
        variant: "error",
      });
    }
  };

  return (
    <Layout
      header={t(title)}
      breadcrumbs={breadcrumbs}
      top={<HeaderBuilder headerData={[]} moduleUrl={moduleUrl} noBackButton={noBackButton} />}
    >
      <FormProvider {...methods} handleDelete={handleOpenDeleteDialog}>
        {isLoading && !ignoreError ? (
          <CircularProgress />
        ) : error.isError && !ignoreError ? (
          <Error error={error} />
        ) : !error.isError && current == null && !ignoreError ? (
          <Error error={{ message: "no data" }} />
        ) : (
          <>
            <form ref={refForm} noValidate onSubmit={methods.handleSubmit(handleSubmit)}>
              {children}
            </form>
            <EditActionButtons
              handleSave={typeof customSaveFunc === "function" ? customSaveFunc : handleSave}
              handleDelete={handleOpenDeleteDialog}
              noDeleteButton={noDeleteButton || id === "new" || newElement}
            />
          </>
        )}
      </FormProvider>
      {openedRemoveDialog && (
        <Suspense>
          <ConfirmationDialog
            open={openedRemoveDialog}
            close={() => setOpenedRemoveDialog(false)}
            confirm={handleDeleteFromDB}
          />
        </Suspense>
      )}
    </Layout>
  );
};

export default EditView;
