import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "layout/Header/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(styles);

const HeaderButton = ({ itemData, action }) => {
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Button
      disabled={itemData.disabled}
      size="small"
      color="primary"
      variant="contained"
      onClick={action}
      className={classes.actionButton}
    >
      {t(itemData.name)}
    </Button>
  );
};

export default HeaderButton;
