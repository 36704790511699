import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const OperationBox = ({ onClick, icon, label }) => {
  return (
    <Box
      sx={{
        height: "auto",
        width: "140px",
        background: alpha(color.lightGray, 0.7),
        borderColor: color.lightGray + "!important",
        borderRadius: 1,
        border: 2,
        padding: 1,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: alpha(color.lightGray, 0.35),
          cursor: "pointer",
          "& .labelOperation": {
            backgroundColor: alpha(color.lightGray, 0.5),
          },
        },
      }}
      onClick={onClick}
    >
      <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
        <Box
          className="labelOperation"
          sx={{
            width: "99%",
            background: alpha(color.lightGray, 1),
            borderRadius: 1,
          }}
        >
          <Typography variant="body1" align="center">
            {label}
          </Typography>
        </Box>
        {icon}
      </Stack>
    </Box>
  );
};
export default OperationBox;
