import { memo, useEffect, useState } from "react";
import { InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Controller, useFormContext } from "react-hook-form";
import FormHelperText from "@mui/material/FormHelperText";

const nullValue = [{ id: "", displayName: " - - - " }];

const CustomizedSelect = ({ label, name, items, disabled = false, defaultValue, noNullValue = false }) => {
  const { control } = useFormContext();

  const [values, setValues] = useState(nullValue);

  useEffect(() => {
    if (!noNullValue) {
      setValues([...nullValue, ...items]);
    } else {
      setValues([...items]);
    }
  }, [items]);

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <FormControl fullWidth size={"small"}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue ? defaultValue : null}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <>
              <Select
                onBlur={onBlur} // notify when input is touched
                onChange={onChange} // send value to hook form
                value={value == undefined || value == null ? "" : value}
                inputRef={ref}
                error={!!formState?.errors[name]}
                disabled={disabled}
                displayEmpty={false}
              >
                {values.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
              {formState?.errors[name] && (
                <FormHelperText error={true}>{formState?.errors[name].message}</FormHelperText>
              )}
            </>
          )}
        />
      </FormControl>
    </>
  );
};

export default memo(CustomizedSelect);
