import { Typography } from "@mui/material";
const Error = ({ error }) => {
    try {
        return (
            <>
                <Typography variant={"h4"}>ERROR</Typography>
                <br/>
                <Typography>
                    {Object?.hasOwn(error, 'status') && (typeof error.status == 'string' || typeof error.status == 'number') && error.status + " - "}
                    {Object?.hasOwn(error, 'error') && (typeof error.error == 'string') && error.error}
                </Typography>
                {Object?.hasOwn(error, 'message') && (typeof error.message == 'string') && <Typography>{error.message}</Typography>}
                {Object?.hasOwn(error, 'message') &&
                    Object?.hasOwn(error.message, 'error')  &&
                    Object?.hasOwn(error.message.error, 'message')  &&
                    (typeof error.message.error.message == 'string') && <Typography>{error.message.error.message}</Typography>}
            </>
        )
    } catch (err) {
        return <Typography variant={"h4"}>ERROR</Typography>
    }
};
export default Error;