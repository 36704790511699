import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "assets/styles/scss/global.scss";
import { PrivateRoute } from "components/PrivateRoute";
import { PublicRoute } from "components/PublicRoute";
import Login from "./modules/Login";
import { fetchMenu } from "redux/slices/menu";
import { fetchCurrentUser, fetchIsLogged, selectIsLogged } from "redux/slices/user";
import Page404 from "modules/Page404";
import UsersList from "modules/Settings/UsersList";
import RolesList from "modules/Settings/RolesList";
import UserEdit from "modules/Settings/UserEdit";
import RoleEdit from "modules/Settings/RoleEdit";
import StartPage from "modules/StartPage";
import LivePage from "modules/Live/LivePage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BrowserPage from "modules/BrowserPage/BrowserPage";
import { INTERNAL_API as API } from "enums/INTERNAL_API";
import AllDevicesList from "modules/Devices/AllDevicesList";
import AllDevicesDetails from "modules/Devices/AllDevicesDetails";
import AllDevicesNew from "modules/Devices/AllDevicesNew";
import AllDevicesEdit from "modules/Devices/AllDevicesEdit";
import HallsList from "modules/Devices/HallsList";
import HallEdit from "modules/Devices/HallEdit";
import TicketPage from "modules/Ticket/TicketPage";
import MyProfileEdit from "modules/Settings/MyProfileEdit";
import ActivityList from "modules/Settings/ActivityList";
import TicketsList from "modules/Ticket/TicketsList";
import SettingsAppEdit from "modules/Settings/SettingsAppEdit";
import UserLoginLogList from "modules/Settings/UserLoginLogList";
import ReportPage from "modules/Reports/ReportPage";
import { REPORT_TYPES } from "./enums/REPORT_TYPES";
import ScenariosValidationPage from "modules/Validation/ScenariosValidationPage";
import RecoilValidationPage from "modules/Validation/RecoilValidationPage";
import LampValidationPage from "modules/Validation/LampValidationPage";
import DetectionValidationPage from "modules/Validation/DetectionValidationPage";
import LiveDevicePage from "modules/LiveDevice/LiveDevicePage";

function App() {
  const isLogged = useSelector(selectIsLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIsLogged());
  }, []);

  useEffect(() => {
    if (isLogged) {
      dispatch(fetchCurrentUser());
      dispatch(fetchMenu());
    }
  }, [isLogged]);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.MAIN} element={<StartPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.LIVE} element={<LivePage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.LIVE_DEVICE}/:uuid`} element={<LiveDevicePage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.BROWSER} element={<BrowserPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.DEVICE} element={<AllDevicesList />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.DEVICE}/:id`} element={<AllDevicesDetails />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.DEVICE}/edit/new`} element={<AllDevicesNew />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.DEVICE}/edit`} element={<Navigate to={API.DEVICE} />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.DEVICE}/edit/:id`} element={<AllDevicesEdit />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.HALLS}`} element={<HallsList />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.HALLS}/:id`} element={<HallEdit />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.VALIDATION_SCENARIOS}`} element={<ScenariosValidationPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.VALIDATION_RECOIL}`} element={<RecoilValidationPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.VALIDATION_LAMP}`} element={<LampValidationPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.VALIDATION_DETECTION}`} element={<DetectionValidationPage />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.REPORT_DAILY}`} element={<ReportPage type={REPORT_TYPES.DAILY} />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.REPORT_PERIODIC}`} element={<ReportPage type={REPORT_TYPES.PERIODIC} />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.TICKET}`} element={<TicketPage />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.TICKET_LOG}`} element={<TicketsList />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.MY_PROFILE} element={<MyProfileEdit />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.USERS} element={<UsersList />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.USERS}/:id`} element={<UserEdit />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.ROLES} element={<RolesList />} />
        </Route>
        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={`${API.ROLES}/:id`} element={<RoleEdit />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.ACTIVITY} element={<ActivityList />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.LOGIN_LOG} element={<UserLoginLogList />} />
        </Route>

        <Route exact element={<PrivateRoute isLogged={isLogged} />}>
          <Route exact path={API.APP_SETTINGS} element={<SettingsAppEdit />} />
        </Route>

        <Route exact element={<PublicRoute isLogged={isLogged} />}>
          <Route exec path={API.LOGIN} element={<Login />} />
        </Route>

        <Route element={<PrivateRoute isLogged={isLogged} />}>
          <Route path="*" exact element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
