const white = "#FFFFFF";
// const lightGray = "#F2F3F4";
// const lightGray = "#F5F4FD";
const lightGray = "#E8ECF4";
const gray = "#E1E3E5";
const darkGray = "#A1A3A5";
const darkerGray = "#AFB6BC";
const ultraGray = "#959ba1";
const black = "#172047";
const blue = "#458ACB";
const darkBlue = "#3b7ab6";
const red = "#D35959";
const lightRed = "#ffe3e3";
const green = "#006400";
const orange = "#FC9527";
const orangeHover = "#d99604";
const primary = "#164195";
const primaryHover = "#285BBD";
const secondaryHover = "#30BADF";

const secondaryMain = "#1BA8CE";
const secondary = secondaryMain;
const submenuBackgroundHover = "rgba(22, 65, 148, 0.1)";
const submenuGroup = "rgba(255, 255, 255, 0.4)";
const menuSelected = "rgba(23, 32, 71, 0.4)";
const menuBackground = "#2E2BDD";
const menuArrow = "rgba(0, 0, 0, 0.7)";

export {
  white,
  lightGray,
  gray,
  darkGray,
  darkerGray,
  ultraGray,
  blue,
  darkBlue,
  red,
  lightRed,
  green,
  orange,
  orangeHover,
  black,
  primary,
  primaryHover,
  secondary,
  secondaryHover,
  submenuBackgroundHover,
  submenuGroup,
  menuSelected,
  menuArrow,
  menuBackground,
};
