import Chart from "react-apexcharts";
import * as color from "assets/styles/jss/common/colors";
import chartColors from "modules/Reports/enum/chartColors";
import { labelsChartDeviceDetectionOnOff } from "modules/Reports/enum/chartLabels";
const ChartDeviceDetectionOnOff = ({ items }) => {
  const options = {
    series: [
      {
        name: labelsChartDeviceDetectionOnOff[0],
        data: items?.cyclesOn,
      },
      {
        name: labelsChartDeviceDetectionOnOff[1],
        data: items?.cyclesOff,
      },
    ],
    options: {
      colors: chartColors,
      chart: {
        id: "chartDeviceDetectionOnOff",
        type: "bar",
        stacked: true,
        stackType: "normal",
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
        toolbar: {
          show: false,
        },
      },
      noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: color.black,
          fontSize: "16px",
        },
      },
      title: {
        text: "There are many variations of passages...",
        align: "left",
        margin: 0,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: color.black,
        },
      },
      responsive: [
        {
          breakpoint: 800,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: items?.deviceNames,
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    },
  };

  return <Chart options={options.options} series={options.series} type="bar" width={"100%"} height="250px" />;
};
export default ChartDeviceDetectionOnOff;
