import Layout from "layout/Layout";
import { useTranslation } from "react-i18next";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { REPORT_TYPES } from "enums/REPORT_TYPES";
import ReportBody from "modules/Reports/components/ReportBody";
import React, { useEffect, useState } from "react";
import HeaderBuilderReports from "layout/Header/HeaderBuilderReports";
import { useSnackbar } from "notistack";
import { format } from "date-fns";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
import pdfGenerator from "modules/Reports/pdf/pdfGenerator";
import csvGenerator from "modules/Reports/pdf/csvGenerator";

const endpoint = EXTERNAL_API.REPORT;

const ReportPage = ({ type }) => {
  const { t } = useTranslation("common");
  const [selectedDate, setSelectedDate] = useState(null);

  const [data, setData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const title =
    type === REPORT_TYPES.DAILY
      ? "reports_daily_label"
      : type === REPORT_TYPES.PERIODIC
      ? "reports_periodic_label"
      : "";

  const breadcrumbsDaily = [
    { name: t("menu_reports"), href: "" },
    { name: t(title), href: INTERNAL_API.REPORT_DAILY },
  ];

  const breadcrumbsPeriodic = [
    { name: t("menu_reports"), href: "" },
    { name: t(title), href: INTERNAL_API.REPORT_PERIODIC },
  ];

  useEffect(() => {
    const getData = () => {
      let d;

      if (type === REPORT_TYPES.DAILY && selectedDate.date) {
        const stringDate = format(selectedDate.date, "dd-MM-yyyy");

        d = { from: stringDate, to: stringDate };
      } else if (type === REPORT_TYPES.PERIODIC && selectedDate.from && selectedDate.to) {
        const stringFrom = format(selectedDate.from, "dd-MM-yyyy");
        const stringTo = format(selectedDate.to, "dd-MM-yyyy");
        d = { from: stringFrom, to: stringTo };
      } else {
        return;
      }

      backend.post(endpoint, d).then((res) => {
        if (isSuccess(res)) {
          setData(res.data);
        } else {
          enqueueSnackbar(t("common_get_data_status_error") + res.status, {
            variant: "error",
          });
        }
      });
    };

    if (selectedDate != null) {
      try {
        getData();
      } catch (error) {
        enqueueSnackbar(t("common_invalid_date_format"), {
          variant: "warning",
        });
      }
    }
  }, [selectedDate, type]);

  return (
    <Layout
      top={
        <HeaderBuilderReports
          type={type}
          setDate={setSelectedDate}
          pdfFunction={() => pdfGenerator(data, t)}
          csvFunction={() => csvGenerator(data)}
        />
      }
      header={t(title)}
      breadcrumbs={
        type === REPORT_TYPES.DAILY ? breadcrumbsDaily : type === REPORT_TYPES.PERIODIC ? breadcrumbsPeriodic : ""
      }
    >
      <ReportBody type={type} data={data} />
    </Layout>
  );
};
export default ReportPage;
