import styles from "./styles";
import { ListItem, SvgIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { ReactComponent as IconOverview } from "assets/img/icon_overview.svg";
import { ReactComponent as IconDevices } from "assets/img/icon_device.svg";
import { ReactComponent as IconReports } from "assets/img/icon_reports.svg";
import { ReactComponent as IconTickets } from "assets/img/icon_ticket.svg";
import { ReactComponent as IconSettings } from "assets/img/icon_settings.svg";

import { MENU_ICON_TYPES } from "enums/menuIconTypes";
import MenuIcon from "@mui/icons-material/Menu";
import { memo } from "react";

const useStyles = makeStyles(styles);

const MenuItem = ({ icon, name, isShow, index, onClickedItem }) => {
  const classes = useStyles();

  const markItem = () => {
    onClickedItem(index);
  };

  const selectIcon = (typeIcon) => {
    switch (typeIcon) {
      case MENU_ICON_TYPES.OVERVIEW_ICON:
        return IconOverview;
      case MENU_ICON_TYPES.DEVICES_ICON:
        return IconDevices;
      case MENU_ICON_TYPES.REPORTS_ICON:
        return IconReports;
      case MENU_ICON_TYPES.TICKETS_ICON:
        return IconTickets;
      case MENU_ICON_TYPES.SETTINGS_ICON:
        return IconSettings;
      default:
        return MenuIcon;
    }
  };

  return (
    <ListItem onClick={markItem} index={index} className={clsx(classes.category, isShow && classes.clicked)}>
      <div className={classes.imgWrapper}>
        <SvgIcon
          index={index}
          component={selectIcon(icon)}
          className={clsx(classes.img, isShow && classes.imgClicked)}
          inheritViewBox
        />
      </div>
      <span className={classes.name}>{name}</span>
    </ListItem>
  );
};

export default memo(MenuItem);
