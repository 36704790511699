import Layout from "layout/Layout";
import { useTranslation } from "react-i18next";
import LiveBody from "modules/Live/Live/LiveBody";
import { useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { LinearProgress } from "@mui/material";
import LiveTitle from "layout/Header/LiveTitle";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const LivePage = () => {
  const { t } = useTranslation("common");
  const [halls, setHalls] = useState([]);
  const [getHallsIsProgress, setHallsIsProgress] = useState(true);

  const breadcrumbs = [
    { name: t("menu_overview"), href: "" },
    { name: t("live_label"), href: INTERNAL_API.LIVE },
  ];

  useEffect(() => {
    backend
      .get(EXTERNAL_API.LIVE_HALLS_ALL_WITH_SELECTED)
      .then((res) => {
        if (isSuccess(res)) {
          setHalls(
            res.data.sort((a, b) => {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            }),
          );
        }
        setHallsIsProgress(false);
      })
      .finally(() => setHallsIsProgress(false));
  }, []);

  return (
    <Layout header={<LiveTitle title={t("live_label")} />} breadcrumbs={breadcrumbs}>
      {getHallsIsProgress ? <LinearProgress /> : <LiveBody halls={halls} />}
    </Layout>
  );
};
export default LivePage;
