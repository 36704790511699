import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import PresentToAll from "@mui/icons-material/PresentToAll";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import * as color from "assets/styles/jss/common/colors";

const FullScreenButton = ({ isFullScreen, setIsFullScreen }) => {
  const { t } = useTranslation("common");

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (document.body.requestFullscreen) {
        document.body.requestFullscreen();
      } else if (document.body.mozRequestFullScreen) {
        // For Old Mozilla Firefox
        document.body.mozRequestFullScreen();
      } else if (document.body.webkitRequestFullscreen) {
        // For Old WebKit (np. Chrome, Safari)
        document.body.webkitRequestFullscreen();
      } else if (document.body.msRequestFullscreen) {
        // For Old Microsoft Internet Explorer and Microsoft Edge
        document.body.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    console.log(setIsFullScreen);
    setIsFullScreen((prev) => !prev);
  };

  return (
    <>
      <IconButton onClick={toggleFullScreen}>
        {isFullScreen ? (
          <Tooltip title={t("device_fullscreen_off")}>
            <CancelPresentationIcon sx={{ color: color.black }} />
          </Tooltip>
        ) : (
          <Tooltip title={t("device_fullscreen_on")}>
            <PresentToAll sx={{ color: color.black }} />
          </Tooltip>
        )}
      </IconButton>
    </>
  );
};
export default memo(FullScreenButton);
