import {Button, Grid, Paper} from "@mui/material";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { INTERNAL_API } from "enums/INTERNAL_API";
import CustomAccordion from "components/CustomAccordion";
import AllDevicesDetailSummary from "modules/Devices/AllDeviceDetails/AllDevicesDetailSummary";
import AllDevicesDetailGetImages from "modules/Devices/AllDeviceDetails/AllDevicesDetailGetImages";
import AllDevicesDetailEvents from "modules/Devices/AllDeviceDetails/AllDevicesDetailEvents";
import { useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import Box from "@mui/material/Box";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
const AllDevicesDetails = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { id } = useParams();
  const [deviceName, setDeviceName] = useState(null);

  const breadcrumbs = [
    { name: t("menu_devices"), href: "" },
    { name: t("devices_label"), href: INTERNAL_API.DEVICE },
    { name: t("devices_label_details"), href: `${INTERNAL_API.DEVICE}/${id}` },
  ];

  useEffect(() => {
    backend.get(EXTERNAL_API.DEVICE_GET_NAME + id).then((res) => {
      if (isSuccess(res)) {
        setDeviceName(res.data.name);
      }
    });
  }, []);

  const toolbarItems = [
    {
      name: "toolbar_button_edit",
      tooltip: "toolbar_button_edit_tooltip",
      icon: "",
      action: "anotherAction",
      disabled: false,
      sortOrder: 0,
      type: "ANOTHER_BUTTON",
    },
  ];

  const handleEdit = () => {
    navigate(`${INTERNAL_API.DEVICE}/edit/${id}`);
  };

  const [isSyncing, setSyncing] = useState(false)

  const handleSyncImages = async () => {
    console.log('aaa')
    setSyncing(true);
    await backend.get(EXTERNAL_API.DEVICE_SYNC_IMAGES + id).then((res) => {
      setSyncing(false);
    });
  }

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      header={
        deviceName ? (
          <>
            {`${t("devices_label_details")} `}
            <Box component="span" className="devices-font">
              {deviceName}
              <Box />
            </Box>
          </>
        ) : (
          `${t("devices_label_details")}`
        )
      }
      top={<HeaderBuilder headerData={toolbarItems} moduleUrl={INTERNAL_API.DEVICE} anotherAction={handleEdit} />}
    >
      <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <AllDevicesDetailSummary />
          </Grid>
          <Grid item xs={12}>
            <CustomAccordion title={t("device_details_get_images")}>
              <Button disabled={isSyncing} onClick={handleSyncImages} variant="contained">{t("synchronize_button")}</Button>
              <AllDevicesDetailGetImages />
            </CustomAccordion>
          </Grid>
          <Grid item xs={12}>
            <CustomAccordion title={t("device_details_events")}>
              <AllDevicesDetailEvents />
            </CustomAccordion>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};
export default AllDevicesDetails;
