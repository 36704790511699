import React, { memo, useState } from "react";
import { ImageListItem, ImageListItemBar } from "@mui/material";

const placeholder = "/time.png";

const LiveGalleryImg = ({ image, name, time }) => {
  const [imageSrc, setImageSrc] = useState(image);
  const handleError = (e) => {
    e.target.error = null;
    setImageSrc(placeholder);

    setTimeout(setOriginalImage, 12000);
  };

  const setOriginalImage = () => {
    setImageSrc(image);
  };

  return (
    <ImageListItem>
      <ImageListItemBar
        title={time}
        position={"top"}
        sx={{
          "& .MuiImageListItemBar-title": { fontSize: "0.9rem" },
        }}
      />
      <img src={imageSrc} alt={name} onError={handleError} />
    </ImageListItem>
  );
};
export default memo(LiveGalleryImg);
