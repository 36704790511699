export const HEAD_CELL_TYPES = {
  ACTION: "ACTION",
  ACTION_DIFF: "ACTION_DIFF",
  DATA: "DATA",
  MINIATURE: "MINIATURE",
  IMAGE_STATUS: "IMAGE_STATUS",
  DIFF_PREVIEW: "DIFF_PREVIEW",
  LINK: "LINK",
  MATERIALS: "MATERIALS",
};
