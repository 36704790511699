import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchMenu = createAsyncThunk('menu/fetchMenu', async (id, thunkAPI) => {
  const url = `/menu/`;
  const res = await backend.get(url);
  return res.data;
});

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    items: [],
    currentSelectedLevelZero: 0,
    currentSelectedLevelOne: "",
    modulePath: "",
    isLoading: false,
  }, reducers: {
    setCurrentSelectedLevelZero(state, action) {
      state.currentSelectedLevelZero = action.payload;
    },
    setCurrentSelectedLevelOne(state, action) {
      state.currentSelectedLevelOne = action.payload;
    },
    setModulePath(state, action) {
      state.modulePath = action.payload;
    },
  },
  extraReducers: {
    [fetchMenu.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchMenu.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [fetchMenu.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
  },
});

export const selectMenuItems = (state) => state.menu.items;
export const selectCurrentSelectedLevelZero = (state) => state.menu.currentSelectedLevelZero;
export const selectCurrentSelectedLevelOne = (state) => state.menu.currentSelectedLevelOne;
export const selectModulePath = (state) => state.menu.modulePath;
export const { setCurrentSelectedLevelZero, setCurrentSelectedLevelOne, setModulePath } = menuSlice.actions;

export default menuSlice.reducer;
