import React, { memo } from "react";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import { Tooltip } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { EVENT_TYPE } from "../../../enums/EVENT_TYPE";
import { alpha } from "@mui/material/styles";

const LiveDetectionsView = ({ value, date }) => {
  let sx = { color: alpha(color.darkGray, 0.4) };
  let title = "";

  switch (value) {
    case EVENT_TYPE.EMPTY:
      sx = { color: color.gray };
      title = "No data";
      break;
    case EVENT_TYPE.NO_WORK:
      title = "Not work";
      sx = { color: color.darkGray };
      break;
    case EVENT_TYPE.CYCLE:
      title = "Working";
      sx = { color: color.green };
      break;
    case EVENT_TYPE.RECOIL:
      title = "Recoil";
      sx = { color: color.red };
      break;
  }

  return (
    <Tooltip title={`${date} ${title}`}>
      <OfflineBoltIcon style={sx} />
    </Tooltip>
  );
};
export default memo(LiveDetectionsView);
