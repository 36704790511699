import React, { forwardRef, memo } from "react";
import Box from "@mui/material/Box";
import LiveImageBox from "modules/Live/Live/LiveImageBox";

const LiveImageTransitionBox = forwardRef((props, ref) => {
  const { name, id, image, deviceId, time, date, handleImagePreview } = props;

  return (
    <Box ref={ref}>
      <LiveImageBox
        name={name}
        id={id}
        image={image}
        deviceId={deviceId}
        time={time}
        date={date}
        handleImagePreview={handleImagePreview}
      />
    </Box>
  );
});

LiveImageTransitionBox.displayName = "LiveImageTransitionBox";

export default memo(LiveImageTransitionBox);
