export const EXTERNAL_API = {
  ACTIVITY: "/activity/",
  CYCLE: "/cycle/",
  DEVICES: "/device/",
  DEVICES_ALL: "/device/all",
  DEVICE_STATUS: "/device/status/",
  DEVICE_GET_NAME: "/device/get-name/",
  DEVICE_SYNC_IMAGES: "/device/synchronize-images/",
  HALLS_ALL: "/hall/all",
  IMAGES: "/images/",
  LIVE_IMAGES_TODAY: "/live/today-images",
  LIVE_SAVE_SETTINGS: "/live/save-live-settings",
  LIVE_HALLS_ALL_WITH_SELECTED: "/live/all-halls-with-selected",
  LIVE_DEVICES: "/live/status-by-hall/",
  LIVE_DEVICE: "/live/device/",
  LOG: "/log",
  MENU_ALL: "/menu/all",
  MY_PROFILE: "/my-profile/",
  PERMISSION: "/groups/permission/",
  RESTARTS: "/restart/",
  REPORT: "/report",
  ROLES: "/roles/",
  ROLES_ALL: "/roles/all",
  SETTINGS_TIME_WINDOWS: "/settings/time-window/",
  SETTINGS_SCANNER: "/settings/scanner/",
  SETTINGS_X_RAY: "/settings/x-ray/",
  SETTINGS_REJECT_SIGNAL: "/settings/reject-signal/",
  SETTINGS_IMAGE: "/settings/image/",
  SETTINGS_DETECTION: "/settings/detection/",
  SETTINGS_APP: "/settings-app/",
  SETTINGS_APP_TRELLO: "/settings-app/trello",
  SETTINGS_FLAP_TEST: "/live-action/flap-test/",
  SETTINGS_LIVE: "/live-action/",
  SETTINGS_LOGIN_LOG: "/audit/",
  TICKETS: "/tickets/",
  TICKETS_ADD: "/tickets/add",
  USERS: "/users/",
};
