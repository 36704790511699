import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.SETTINGS_IMAGE;
const AllDevicesEditPhotoSettings = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const selectValue = [
    { id: true, displayName: t("device_edit_save_image_yes") },
    { id: false, displayName: t("device_edit_save_image_no") },
  ];

  const validationSchema = Yup.object({
    saveOriginal: Yup.string().trim().required(t("form-validation-required")),
    saveAfterProcess: Yup.boolean().required(t("form-validation-required")),
    saveAfterDet: Yup.string().trim().required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data;

        for (const dataKey in item) {
          methods.setValue(dataKey, item[dataKey]);
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    backend.post(endpoint + id, formData).then((res) => {
      if (isSuccess(res)) {
        methods.setValue("lastModification", res.data["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const lastModification = methods.watch("lastModification");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_save_the_original")}
              name="saveOriginal"
              items={selectValue}
              defaultValue={"false"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_post-processing_record")}
              name="saveAfterProcess"
              items={selectValue}
              defaultValue={"false"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_recording_after_detection")}
              name="saveAfterDet"
              items={selectValue}
              defaultValue={"false"}
              noNullValue
            />
          </Grid>
          {lastModification && (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lastModification}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" variant={"contained"} size="small">
              {t("button_save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default AllDevicesEditPhotoSettings;
