import { Hidden, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import styles from "layout/Header/styles";
import HeaderBreadcrumbs from "layout/Header/HeaderBreadcrumbs";

const useStyles = makeStyles(styles);

const Header = ({ children, mobileToggle, mobileOpened, top, breadcrumbs }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.upperSection}>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open mobile menu"
            onClick={mobileToggle}
            className={classes.smallButton}
            size="large"
          >
            {!mobileOpened && <MenuIcon />}
            {mobileOpened && <CloseIcon />}
          </IconButton>
        </Hidden>
      </div>
      <div className={classes.label}>
        <Typography variant="h1">{children}</Typography>
        {breadcrumbs && <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />}
      </div>
      <div className={classes.actions}>{top}</div>
    </div>
  );
};

export default Header;
