export const MATERIAL_TYPE = {
  MEDICAL_STRIP: { id: "MEDICAL_STRIP", t: "browser_table_material_medical_strip" },
  CERAMICS: { id: "CERAMICS", t: "browser_table_material_ceramics" },
  GLASS: { id: "GLASS", t: "browser_table_material_glass" },
  METAL: { id: "METAL", t: "browser_table_material_metal" },
  NOT_DETECT: { id: "NOT_DETECT", t: "browser_table_material_not_detect" },
};

export const getT = (materialKey) => {
  if (materialKey in MATERIAL_TYPE) {
    return MATERIAL_TYPE[materialKey].t;
  } else {
    return "?";
  }
};
