import React from "react";
import TableCell from "@mui/material/TableCell";
import { Avatar, Link, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import { useTranslation } from "react-i18next";
import ImageIcon from "@mui/icons-material/Image";

const CellMiniature = ({ data, handlePreview, row }) => {
  const { t } = useTranslation("common");

  return (
    <TableCell>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Link onClick={() => handlePreview(row)}>
          <Avatar src={data} alt={row.name} sx={{ width: 60, height: 40, background: "black" }} variant="square">
            <ImageIcon sx={{ color: color.darkGray }} />
          </Avatar>
        </Link>

        <Tooltip title={t("table_action_cell_preview")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
          <IconButton size="small" onClick={() => handlePreview(row)}>
            <SearchIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  );
};

export default CellMiniature;
