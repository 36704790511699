import { Button, Pagination, Stack } from "@mui/material";

const PaginationTable = ({ maxPages, actualPage, setActualPage }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Button
          variant="text"
          size="small"
          onClick={() => setActualPage((prev) => prev - 1)}
          disabled={actualPage === 1}
        >
          {"<"}
        </Button>
        <Pagination
          count={maxPages}
          hideNextButton
          hidePrevButton
          page={actualPage}
          onChange={(e, value) => setActualPage(value)}
        />
        <Button
          size="small"
          variant="text"
          onClick={() => setActualPage((prev) => prev + 1)}
          disabled={maxPages === actualPage}
        >
          {">"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default PaginationTable;
