import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Input, InputLabel, Typography } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: alpha(color.primary, 0.15),
  borderStyle: "dashed",
  backgroundColor: alpha(color.primary, 0.05),
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const CustomizedDropZoneAllFile = ({ label, selectedFile }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length < 10) {
      selectedFile(acceptedFiles);
    } else {
      enqueueSnackbar(t("submit_ticket_failed_to_add_file"), {
        variant: "warning",
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 5, maxSize: 10000000 });

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    [],
  );

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Box>
        <Box {...getRootProps({ style })}>
          <Input {...getInputProps()} />
          <FileDownloadIcon />
          <Typography>{t("submit_ticket_drag_or_select_file")}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default CustomizedDropZoneAllFile;
