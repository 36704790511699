import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Menu";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { selectMenuItems, setCurrentSelectedLevelOne, setCurrentSelectedLevelZero } from "redux/slices/menu";
import { findMenu } from "utils/menu";
import { reactLocalStorage } from "reactjs-localstorage";
import { useLocation } from "react-router-dom";
import { LOCAL_STORAGE_NAMES } from "../enums/LOCAL_STORAGE_NAMES";

const useStyles = makeStyles(styles);

const Layout = ({ header, children, bottom, top, breadcrumbs }) => {
  reactLocalStorage.set(LOCAL_STORAGE_NAMES.LAST_LOCATION, useLocation()?.pathname);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleClose = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = useSelector(selectMenuItems);
  useEffect(() => {
    const { levelZero, levelOne } = findMenu(menuItems);

    dispatch(setCurrentSelectedLevelZero(levelZero));
    dispatch(setCurrentSelectedLevelOne(levelOne));
  }, [menuItems]);

  return (
    <Box className={classes.layoutContainer}>
      <Box sx={{ display: { xs: "none", md: "block" }, position: "fixed" }}>
        <Sidebar />
      </Box>

      {mobileOpen && (
        <Box
          id="mobile-menu"
          className={classes.mobileMenu}
          sx={{ zIndex: "modal", display: { md: "none", xs: "block" } }}
        >
          <Sidebar showMobile={mobileOpen} />
        </Box>
      )}

      <Box className={classes.mainWrapper} sx={{ ml: { xs: 0, md: "375px" } }}>
        <Header
          mobileToggle={handleClose}
          bottom={bottom}
          top={top}
          mobileOpened={mobileOpen}
          breadcrumbs={breadcrumbs}
        >
          {header}
        </Header>
        <Box className={classes.main} id="main">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
export default Layout;
