import { Grid, Paper } from "@mui/material";
import HeaderBuilder from "layout/Header/HeaderBuilder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "layout/Layout";
import CustomAccordion from "components/CustomAccordion";
import AllDevicesEditMain from "modules/Devices/AllDevicesEdit/AllDevicesEditMain";
import AllDevicesEditTimeWindow from "modules/Devices/AllDevicesEdit/AllDevicesEditTimeWindow";
import AllDevicesEditRejectSignal from "modules/Devices/AllDevicesEdit/AllDevicesEditRejectSignal";
import AllDevicesEditPhotoSettings from "modules/Devices/AllDevicesEdit/AllDevicesEditPhotoSettings";
import AllDevicesEditScannerSettings from "modules/Devices/AllDevicesEdit/AllDevicesEditScannerSettings";
import AllDevicesEditDetectionSettings from "modules/Devices/AllDevicesEdit/AllDevicesEditDetectionSettings";
import AllDevicesEditRtgSettings from "modules/Devices/AllDevicesEdit/AllDevicesEditRtgSettings";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { useParams } from "react-router-dom";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import Box from "@mui/material/Box";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
import AllDevicesOperations from "modules/Devices/AllDevicesEdit/AllDevicesOperations";
const AllDevicesEdit = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const [deviceName, setDeviceName] = useState(null);

  const breadcrumbs = [
    { name: t("menu_devices"), href: "" },
    { name: t("devices_label"), href: INTERNAL_API.DEVICE },
    { name: t("devices_label_details"), href: `${INTERNAL_API.DEVICE}/${id}` },
    { name: t("devices_label_edit"), href: `${INTERNAL_API.DEVICE_SETTINGS}/${id}` },
  ];

  useEffect(() => {
    backend.get(EXTERNAL_API.DEVICE_GET_NAME + id).then((res) => {
      if (isSuccess(res)) {
        setDeviceName(res.data.name);
      }
    });
  }, []);

  return (
    <Layout
      breadcrumbs={breadcrumbs}
      header={
        deviceName ? (
          <>
            {`${t("devices_label_edit")} `}
            <Box component="span" className="devices-font">
              {deviceName}
              <Box />
            </Box>
          </>
        ) : (
          `${t("devices_label_edit")}`
        )
      }
      top={<HeaderBuilder headerData={[]} moduleUrl={`${INTERNAL_API.DEVICE}/${id}`} />}
    >
      <Paper sx={{ p: 10 }} elevation={8}>
        <Grid container spacing={5}>
          {/* left column */}
          <Grid item xs={12} sm={5}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_settings")}>
                  <AllDevicesEditMain />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_time_window")}>
                  <AllDevicesEditTimeWindow />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_reject_signal_settings")}>
                  <AllDevicesEditRejectSignal />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_save_image_settings")}>
                  <AllDevicesEditPhotoSettings />
                </CustomAccordion>
              </Grid>
            </Grid>
          </Grid>
          {/* right column */}
          <Grid item xs={12} sm={7}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_live_operation")}>
                  <AllDevicesOperations />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_scanner_settings")}>
                  <AllDevicesEditScannerSettings />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_detection_settings")}>
                  <AllDevicesEditDetectionSettings />
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion title={t("device_edit_x-ray_generator_settings")}>
                  <AllDevicesEditRtgSettings />
                </CustomAccordion>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Layout>
  );
};
export default AllDevicesEdit;
