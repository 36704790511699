import React, { Suspense, useCallback, useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { TransitionGroup } from "react-transition-group";
import Slide from "@mui/material/Slide";
import { EXTERNAL_API } from "enums/EXTERNAL_API";
import LiveImageTransitionBox from "modules/Live/Live/LiveImageTransitionBox";
const ImagePreviewDialog = React.lazy(() => import("components/ImagePreviewDialog"));

const endpoint = EXTERNAL_API.LIVE_IMAGES_TODAY;

const LastDetectionImages = ({ selectedHalls }) => {
  const [images, setImages] = useState([]);

  const [imagePreviewDialogOpen, setImagePreviewDialogOpen] = useState(false);
  const [previewedImage, setPreviewedImage] = useState(null);
  const [idImageToEdit, setIdImageToEdit] = useState(null);

  useEffect(() => {
    if (idImageToEdit) {
      const image = images.find((item) => item.id === idImageToEdit);
      setPreviewedImage(image);

      setImagePreviewDialogOpen(true);
    }
  }, [idImageToEdit]);

  const refreshData = () => {
    if (selectedHalls.length > 0) {
      backend.post(endpoint, selectedHalls).then((response) => {
        if (isSuccess(response)) {
          setImages([...response.data]);
        } else {
          console.error(response);
        }
      });
    }
  };

  useEffect(() => {
    refreshData();

    const interval = setInterval(() => {
      refreshData();
    }, 16000);
    return () => clearInterval(interval);
  }, [selectedHalls]);

  const handleImagePreview = useCallback((id) => {
    setIdImageToEdit(id);
  }, []);

  const handleImagePreviewClosed = (isActionDone) => {
    if (isActionDone) {
      refreshData();
    }
    setIdImageToEdit(null);
    setPreviewedImage(null);
    setImagePreviewDialogOpen(false);
  };

  return (
    <>
      {images?.length > 0 && (
        <TransitionGroup className="live-images">
          {images.map((item) => (
            <Slide direction="right" timeout={450} key={item.id}>
              <LiveImageTransitionBox
                key={item.id}
                name={item.name}
                id={item.id}
                image={item.image}
                deviceId={item.deviceId}
                time={item.time}
                date={item.date}
                handleImagePreview={handleImagePreview}
              />
            </Slide>
          ))}
        </TransitionGroup>
      )}
      {imagePreviewDialogOpen && (
        <Suspense>
          <ImagePreviewDialog data={previewedImage} open={imagePreviewDialogOpen} close={handleImagePreviewClosed} />
        </Suspense>
      )}
    </>
  );
};
export default LastDetectionImages;
