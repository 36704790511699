import React from "react";
import TableCell from "@mui/material/TableCell";
import { Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const CellLink = ({ data }) => {
  const { t } = useTranslation("common");

  return (
    <TableCell>
      <Link href={data} target={"_blank"}>
        {data}
      </Link>
    </TableCell>
  );
};

export default CellLink;
