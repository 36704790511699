import HeaderTextFieldsSearch from "layout/Header/HeaderTextFieldsSearch";
import Toolbar from "@mui/material/Toolbar";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Box, Chip, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { format } from "date-fns";
import { setAttribute } from "redux/slices/crud";
import { useDispatch } from "react-redux";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const HeaderBuilder = ({ searchFieldName = "name", dateFieldName = "datetime" }) => {
  const { t } = useTranslation("common");
  const [devices, setDevices] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState([]);
  const dispatch = useDispatch();

  const toolbarItems = {
    name: "toolbar_search_field",
    tooltip: "toolbar_search_field_tooltip",
    icon: "Search",
    action: "search",
    disabled: false,
    sortOrder: 4,
    type: "FIELDS_SEARCH",
    fieldName: `${searchFieldName}:like`,
  };

  useEffect(() => {
    backend.get(EXTERNAL_API.DEVICES_ALL).then((res) => {
      if (isSuccess(res)) {
        setDevices(res.data);
      }
    });
  }, []);

  useEffect(() => {
    let stringValue = "";

    for (const device of selectedDevice) {
      if (stringValue.length > 0) {
        stringValue += ",";
      }
      stringValue += device;
    }

    dispatch(setAttribute({ name: "device:list", value: stringValue }));
  }, [selectedDevice]);

  const handleClickDevice = (device) => {
    if (!isChecked(device)) {
      setSelectedDevice((prevState) => [...prevState, device.id]);
    } else {
      setSelectedDevice((prevState) => {
        return prevState.filter((item) => item !== device.id);
      });
    }
  };

  const handleSetFrom = (from) => {
    setFrom(from);
    try {
      const stringDate = format(from, "dd-MM-yyyy HH:mm");
      dispatch(setAttribute({ name: `${dateFieldName}:dgte`, value: stringDate }));
    } catch (err) {
      dispatch(setAttribute({ name: `${dateFieldName}:dgte`, value: "" }));
      console.log(err);
    }
  };
  const handleSetTo = (to) => {
    setTo(to);
    try {
      const stringDate = format(to, "dd-MM-yyyy HH:mm");
      dispatch(setAttribute({ name: `${dateFieldName}:dlte`, value: stringDate }));
    } catch (err) {
      dispatch(setAttribute({ name: `${dateFieldName}:dlte`, value: "" }));
      console.log(err);
    }
  };

  const isChecked = (device) => {
    return selectedDevice.some((item) => item === device.id);
  };

  return (
    <Toolbar>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} md={4}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <DateTimePicker
              label={t("browser_filter_from")}
              value={from}
              onChange={handleSetFrom}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={t("browser_filter_from")}
                />
              )}
              inputFormat={"dd-MM-yyyy HH:mm"}
            />
            <DateTimePicker
              label={t("browser_filter_to")}
              value={to}
              onChange={handleSetTo}
              renderInput={(params) => <TextField fullWidth {...params} variant="outlined" size="small" />}
              inputFormat={"dd-MM-yyyy HH:mm"}
            />
          </Stack>
        </Grid>
        <Grid item xs={0} sm={1} md={6} />
        <Grid item xs={12} sm={4} md={2}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <HeaderTextFieldsSearch itemData={toolbarItems} fullWidth />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box>
            {devices.map((device) => (
              <Chip
                className="devices-font"
                color="primary"
                sx={{ borderRadius: 2, margin: 1 }}
                variant={isChecked(device) ? "filled" : "outlined"}
                key={device.id}
                label={device.name}
                onClick={() => handleClickDevice(device)}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default HeaderBuilder;
