import TableCell from "@mui/material/TableCell";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import React, { Suspense } from "react";
const CellActionDiffPopover = React.lazy(() => import("components/Table/CellActionDiffPopover"));

const CellActionDiff = ({ row }) => {
  const { t } = useTranslation("common");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <TableCell align="left">
        <Tooltip title={t("table_action_cell_preview")} placement="bottom" arrow={true} sx={{ marginRight: "10px" }}>
          <IconButton size="small" aria-describedby={id} onClick={handleClick}>
            <SearchIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
      </TableCell>
      {open && (
        <Suspense>
          <CellActionDiffPopover open={open} handleClose={handleClose} id={id} anchorEl={anchorEl} text={row.diff} />
        </Suspense>
      )}
    </>
  );
};

export default CellActionDiff;
