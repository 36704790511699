import Layout from "layout/Layout";
import { useTranslation } from "react-i18next";
import { Button, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import CustomizedDropZoneAllFile from "components/Edit/CustomizedDropZoneAllFile";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { isSuccess } from "utils/http";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useEffect, useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Stack from "@mui/material/Stack";
import backend from "api/backend";
import { useSelector } from "react-redux";
import { selectUser } from "redux/slices/user";
import { INTERNAL_API } from "enums/INTERNAL_API";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const selectValues = [
  { id: "ERROR", displayName: "Error" },
  { id: "FAILURE", displayName: "Failure" },
  { id: "OTHER", displayName: "Other" },
];

// const trelloSettings = {
//   key: "2919c5855dae0f104a74950b6a742c18",
//   token: "ATTAc2b8b520db4df32ba96156b7f197340c67f35b1f9986c68d3305feb3c776da848F9CB743",
//   tickets: {
//     FAILURE: "53201aab2b945d160f5d7e09",
//     ERROR: "53201aab2b945d160f5d7e0a",
//     OTHER: "63b29403f8641d062e034f9b",
//   },
// };

const endpoint = EXTERNAL_API.TICKETS_ADD;

const TicketPage = () => {
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector(selectUser);

  const [trelloSettings, setTrelloSettings] = useState(null);

  const breadcrumbs = [
    { name: t("menu_tickets"), href: "" },
    { name: t("submit_ticket_label"), href: INTERNAL_API.TICKET },
  ];

  const [files, setFiles] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const validationSchema = Yup.object({
    subject: Yup.string().trim().required(t("form-validation-required")),
    caseType: Yup.string().trim().required(t("form-validation-required")),
    contents: Yup.string().trim().required(t("form-validation-required")),
  });

  const defaultValue = {
    subject: "",
    caseType: "OTHER",
    contents: "",
  };

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (formData) => {
    if (!trelloSettings) {
      enqueueSnackbar(t("common_status_sending_error"), {
        variant: "error",
      });
      return;
    }

    setIsSending(true);
    let signature = `\n\n[${user.userName} - ${user.login} ]`;

    if (user.roles.length > 0) {
      signature += `\n[roles: ${user.roles.toString()}]`;
    }

    const ticket = trelloSettings?.tickets?.[formData.caseType];
    const url = `https://api.trello.com/1/cards?idList=${ticket}&key=${trelloSettings["key"]}&token=${trelloSettings["token"]}`;

    const data = {
      pos: "top",
      name: formData.subject,
      desc: formData.contents + signature,
    };

    let dataToSave = { ...formData, signature: signature, filesCount: files.length, shortUrl: "" };

    axios
      .post(url, data)
      .then(async (res) => {
        if (isSuccess(res)) {
          dataToSave = { ...dataToSave, shortUrl: res.data.shortUrl };
          const id = res.data.id;

          await sendFile(id).then((res) => {
            resetForm();

            setIsSending(false);

            enqueueSnackbar(t("common_status_successfully"), {
              variant: "success",
            });
          });
        } else {
          setIsSending(false);
          enqueueSnackbar(t("common_status_sending_error"), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("common_status_sending_error"), {
          variant: "error",
        });
        setIsSending(false);
      })
      .finally(() => {
        saveToDB(dataToSave);
      });
  };

  const saveToDB = (dataToSave) => {
    backend.post(endpoint, dataToSave).then((res) => {
      if (!isSuccess(res)) {
        console.error(res);
      }
    });
  };

  const sendFile = async (id) => {
    const url = `https://api.trello.com/1/cards/${id}/attachments?&key=${trelloSettings["key"]}&token=${trelloSettings["token"]}`;

    const resp = [];

    for (const file of files) {
      const fd = new FormData();
      fd.append("file", file, file.name);

      const res = axios.post(url, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      resp.push(res);
    }

    await Promise.all(resp);
  };

  const handleSelectedFile = (addFiles) => {
    setFiles((prev) => [...prev, ...addFiles]);
  };

  const handelRemoveFile = (index) => {
    setFiles((prevState) => {
      const newArray = [...prevState];
      newArray.splice(index, 1);
      return newArray;
    });
  };

  const resetForm = () => {
    methods.reset(defaultValue);
    setFiles([]);
  };

  useEffect(() => {
    backend.get(EXTERNAL_API.SETTINGS_APP_TRELLO).then((res) => {
      if (isSuccess(res)) {
        setTrelloSettings({
          key: res.data["key"],
          token: res.data["token"],
          tickets: {
            FAILURE: res.data["ticketFailure"],
            ERROR: res.data["ticketError"],
            OTHER: res.data["ticketOther"],
          },
        });
      }
    });
  }, []);

  return (
    <Layout header={t("submit_ticket_label")} breadcrumbs={breadcrumbs}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={8}>
              <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <CustomizedTextField label={t("submit_ticket_subject")} name={"subject"} />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedSelect
                      label={t("submit_ticket_case_type")}
                      name={"caseType"}
                      noNullValue
                      defaultValue={"OTHER"}
                      items={selectValues}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomizedTextField label={t("submit_ticket_contents")} name={"contents"} multiline minRows={6} />
                  </Grid>
                  {isSending && (
                    <Grid item xs={12}>
                      {t("submit_ticket_sending")}
                      <LinearProgress />
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
                <CustomizedDropZoneAllFile
                  label={t("submit_ticket_add_attachment")}
                  selectedFile={handleSelectedFile}
                />
                {files.map((file, index) => (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    key={index}
                    sx={{ marginY: 4 }}
                  >
                    <Typography variant="body2">{file.name}</Typography>
                    <Button onClick={() => handelRemoveFile(index)}>
                      <DeleteOutlineIcon />
                    </Button>
                  </Stack>
                ))}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" type="submit" disabled={isSending}>
                {t("submit_ticket_send")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Layout>
  );
};
export default TicketPage;
