import { Button, Grid } from "@mui/material";
import * as color from "assets/styles/jss/common/colors";
import { useTranslation } from "react-i18next";

const EditActionButtons = ({ handleSave, handleDelete, noDeleteButton }) => {
  const { t } = useTranslation("common");
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        {!noDeleteButton && (
          <Button variant="outlined" color="default" onClick={handleDelete} sx={{ color: color.red }}>
            {t("button_remove")}
          </Button>
        )}
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t("button_save")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditActionButtons;
