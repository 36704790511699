import { Image } from "react-native-web";
import jsPDF from "jspdf";
import apexcharts from "apexcharts";
import chartColors from "modules/Reports/enum/chartColors";
import {
  labelsChartDeviceDetectionOnOff,
  labelsRadialChartCycleOnOff,
  labelsRadialChartDeviceDetectionOnOff,
} from "modules/Reports/enum/chartLabels";

const generateData = (keys, data) => {
  const result = [];
  for (const element of data) {
    const data = {};
    for (const key of keys) {
      data[key["fieldName"]] = element[key["fieldName"]].toString();
    }
    result.push(data);
  }
  return result;
};

const createHeaders = (keys) => {
  const result = [];
  for (const key of keys) {
    result.push({
      id: key["fieldName"],
      name: key["fieldName"],
      prompt: key["displayName"],
      width: 65,
      align: "center",
      padding: 0,
    });
  }
  return result;
};

const getImageSize = (uri) => {
  return new Promise((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => {
        resolve({ width, height });
      },
      reject,
    );
  });
};

const addLegendItem = (x, y, color, text, pdf) => {
  pdf.setFillColor(color);
  pdf.circle(x, y, 1.5, "F");
  pdf.text(text, x + 3, y + 1);
};

const pdfGenerator = async (data, t) => {
  const reportTableDetectionOnHeader = [
    { fieldName: "deviceName", displayName: t("reports_table_device_name") },
    { fieldName: "cycleOn", displayName: t("reports_table_cycle_detection_on") },
    { fieldName: "cycleOff", displayName: t("reports_table_cycle_detection_off") },
    { fieldName: "cycleAll", displayName: t("reports_table_cycle_detection_all") },
    { fieldName: "recoils", displayName: t("reports_table_cycle_recoils") },
    { fieldName: "restartsAll", displayName: t("reports_table_cycle_restarts") },
  ];

  const reportTableDetectionOffHeader = [
    { fieldName: "deviceName", displayName: t("reports_table_device_name") },
    { fieldName: "cycleOff", displayName: t("reports_table_cycle_detection_off") },
    { fieldName: "restartsAll", displayName: t("reports_table_cycle_restarts") },
  ];

  const pxTOmm = 0.264583;

  const pdf = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
  });
  pdf.setFontSize(9);
  pdf.text(`${data?.creationDate} `, 190, 20, {
    align: "right",
  });
  pdf.setFontSize(12);
  pdf.text(`Report - Summary`, 100, 30, {
    align: "center",
  });
  pdf.setFontSize(9);
  pdf.text(`from ${data?.from} to ${data?.from}`, 100, 35, {
    align: "center",
  });

  let nextY = 60;

  /* charts - 1 */
  const radialChartCycleOnOff = await apexcharts.exec("radialChartCycleOnOff", "dataURI");
  let dimension = await getImageSize(radialChartCycleOnOff.imgURI);
  let height = 40;
  let width = (40 / (dimension.height * pxTOmm)) * dimension.width * pxTOmm;

  if (width > 80) {
    width = 80;
    height = (80 / (dimension.width * pxTOmm)) * dimension.height * pxTOmm;
  }
  pdf.addImage(radialChartCycleOnOff.imgURI, "PNG", 20, nextY, width, height);
  addLegendItem(20 + 42, nextY + 10, chartColors[0], labelsRadialChartCycleOnOff[0], pdf);
  addLegendItem(20 + 42, nextY + 15, chartColors[1], labelsRadialChartCycleOnOff[1], pdf);

  /* charts - 2 */
  const radialChartDeviceDetectionOnOff = await apexcharts.exec("radialChartDeviceDetectionOnOff", "dataURI");
  dimension = await getImageSize(radialChartDeviceDetectionOnOff.imgURI);
  height = 40;
  width = (40 / (dimension.height * pxTOmm)) * dimension.width * pxTOmm;

  if (width > 80) {
    width = 80;
    height = (80 / (dimension.width * pxTOmm)) * dimension.height * pxTOmm;
  }

  pdf.addImage(radialChartDeviceDetectionOnOff.imgURI, "PNG", 110, nextY, width, height);
  addLegendItem(110 + 42, nextY + 10, chartColors[0], labelsRadialChartDeviceDetectionOnOff[0], pdf);
  addLegendItem(110 + 42, nextY + 15, chartColors[1], labelsRadialChartDeviceDetectionOnOff[1], pdf);
  addLegendItem(110 + 42, nextY + 20, chartColors[2], labelsRadialChartDeviceDetectionOnOff[2], pdf);

  nextY += height + 20;
  /* charts - 3 */
  const chartDeviceDetectionOnOff = await apexcharts.exec("chartDeviceDetectionOnOff", "dataURI");
  dimension = await getImageSize(chartDeviceDetectionOnOff.imgURI);
  height = dimension.height * pxTOmm;
  width = dimension.width * pxTOmm;

  if (width > 170) {
    height = (170 / (dimension.width * pxTOmm)) * dimension.height * pxTOmm;
    width = 170;
  }

  pdf.addImage(chartDeviceDetectionOnOff.imgURI, "PNG", 20, nextY, width, height); // const chartDeviceDetectionOnOff = await apexcharts.exec("chartDeviceDetectionOnOff", "dataURI");
  addLegendItem(20 + 5, nextY + height + 3, chartColors[0], labelsChartDeviceDetectionOnOff[0], pdf);
  addLegendItem(50, nextY + height + 3, chartColors[1], labelsChartDeviceDetectionOnOff[1], pdf);

  /* cycles on table */
  if (data?.reportItemsDetectionOn && data?.reportItemsDetectionOn.length > 0) {
    pdf.addPage("a4", "p");
    pdf.setFontSize(9);
    pdf.text(`${data?.creationDate} `, 190, 20, {
      align: "right",
    });
    pdf.setFontSize(12);
    pdf.text(`Report - ${t("reports_device_with_detection_on")}`, 100, 30, {
      align: "center",
    });

    const headers = createHeaders(reportTableDetectionOnHeader);
    const dataForTable = generateData(reportTableDetectionOnHeader, data?.reportItemsDetectionOn);
    pdf.table(20, 40, dataForTable, headers, { autoSize: true, fontSize: 11 });
  }

  /* cycles off table */
  if (data?.reportItemsDetectionOff && data?.reportItemsDetectionOff.length > 0) {
    pdf.addPage("a4", "p");
    pdf.setFontSize(9);
    pdf.text(`${data?.creationDate} `, 190, 20, {
      align: "right",
    });
    pdf.setFontSize(12);
    pdf.text(`Report - ${t("reports_device_with_detection_off")}`, 100, 30, {
      align: "center",
    });

    const headers = createHeaders(reportTableDetectionOffHeader);
    const dataForTable = generateData(reportTableDetectionOffHeader, data?.reportItemsDetectionOff);
    pdf.table(20, 40, dataForTable, headers, { autoSize: true, fontSize: 11 });
  }

  pdf.save(`report-${data?.creationDate.replaceAll(" ", "-").replaceAll(":", "-")}.pdf`);
};

export default pdfGenerator;
