import styles from "./styles.js";
import makeStyles from "@mui/styles/makeStyles";
import SubmenuUl from "../SubmenuUl/SubmenuUl.js";
import clsx from "clsx";
import React, { memo } from "react";
import EastIcon from "@mui/icons-material/East";
import { ListItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentSelectedLevelOne, setCurrentSelectedLevelOne } from "redux/slices/menu";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(styles);

const Submenu = ({ group }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const link = useSelector(selectCurrentSelectedLevelOne);

  const markLink = (page) => {
    dispatch(setCurrentSelectedLevelOne(page.path));
    navigate(page.path);
  };

  return group.map((group, gIndex) => (
    <SubmenuUl name={group.name} key={"group" + gIndex}>
      {group.pages.map((page, sIndex) => (
        <ListItem
          onClick={() => markLink(page)}
          key={"page.title" + gIndex + sIndex}
          className={clsx(classes.submenuGroup__li, page.path === link && classes.clickedSubmenu)}
          link={page.path}
        >
          <Typography variant={"body3"} className={classes.submenuLink}>
            {page.title}
          </Typography>
          <EastIcon className={classes.arrow} />
        </ListItem>
      ))}
    </SubmenuUl>
  ));
};

export default memo(Submenu);
