import { useTranslation } from "react-i18next";
import { Grid, Table, Typography } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

const ReportTableDetectionOff = ({ items }) => {
  const { t } = useTranslation("common");

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Typography variant="h4">{t("reports_device_with_detection_off")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "left", width: "10%" }}>{t("reports_table_device_name")}</TableCell>
                <TableCell sx={{ textAlign: "right", width: "45%", whiteSpace: "pre" }}>
                  {t("reports_table_cycle_detection_off")}
                </TableCell>
                <TableCell sx={{ textAlign: "right", width: "45%" }}>{t("reports_table_cycle_restarts")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item.deviceId}>
                  <TableCell align="left" className="devices-font">
                    {item.deviceName}
                  </TableCell>
                  <TableCell align="right">{item.cycleOff}</TableCell>
                  <TableCell align="right">{item.restartsAll}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default ReportTableDetectionOff;
