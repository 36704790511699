import { ButtonGroup, Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import DevicesStatus from "modules/Live/Live/DevicesStatus";
import LastDetectionImages from "modules/Live/Live/LastDetectionImages";
import Stack from "@mui/material/Stack";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import LiveTab from "modules/Live/Live/LiveTab";
import { EXTERNAL_API } from "../../../enums/EXTERNAL_API";

const LiveBody = ({ halls }) => {
  const [selectedHalls, setSelectedHalls] = useState([]);
  const [blockSaveSettingsHalls, setBlockSaveSettingsHalls] = useState(true);

  useEffect(() => {
    setSelectedHalls(() => {
      const tmpHalls = [...halls];
      const tmpHallsSelected = tmpHalls.filter((item) => item.selected);

      return tmpHallsSelected.map((item) => item.id);
    });
    setBlockSaveSettingsHalls(false);
  }, [halls]);

  useEffect(() => {
    const setSelectedHall = () => {
      backend
        .post(EXTERNAL_API.LIVE_SAVE_SETTINGS, selectedHalls)
        .then((response) => {
          if (!isSuccess(response)) {
            console.error(response);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    if (selectedHalls.length > 0 && !blockSaveSettingsHalls) {
      setSelectedHall();
    }
  }, [selectedHalls]);

  const handleChangeSelectedHalls = (id, selected) => {
    if (selected && selectedHalls.length > 1) {
      setSelectedHalls((prevState) => {
        const index = prevState.indexOf(id);
        prevState.splice(index, 1);
        return [...prevState];
      });
    } else if (!selected) {
      setSelectedHalls((prevState) => [...prevState, id]);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ mb: 5 }}>
          <ButtonGroup variant="text" aria-label="text button group">
            {halls.map((hall) => (
              <LiveTab
                key={hall.id}
                hall={hall}
                handleChangeSelectedHalls={handleChangeSelectedHalls}
                selectedHalls={selectedHalls}
              />
            ))}
          </ButtonGroup>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: "20px" }}>
        {<LastDetectionImages selectedHalls={selectedHalls} />}
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 10, mb: 10 }} elevation={8}>
          <DevicesStatus selectedHalls={selectedHalls} />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default LiveBody;
