import { useTranslation } from "react-i18next";
import React, { memo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ChipStatus from "modules/Live/Live/ChipStatus";
import { Grid, Stack, Typography } from "@mui/material";
import TableHead from "@mui/material/TableHead";

const LiveStatusView = ({
  tegra,
  status,
  isRecoil,
  cycle,
  allCycle,
  recoilToday,
  allRecoil,
  seasoning,
  seasoningType,
}) => {
  const { t } = useTranslation("common");

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} sm={6}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <ChipStatus value={tegra === "on"} />
          <Typography>{t("device_live_power")}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} sm={6}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <ChipStatus value={status === "on"} />
          <Typography>{t("device_live_detection")}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} sm={6}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <ChipStatus value={isRecoil} />
          <Typography>{t("device_live_reject_signal")}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3} sm={6}>
        <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}>
          <ChipStatus value={seasoning} />
          <Typography>{t("device_live_seasoning")}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12}>
        {seasoning && (
          <>
            {t("device_live_seasoning_type")} {seasoningType}
          </>
        )}
      </Grid>
      <Grid item xs={12} sx={{ mt: "2rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ background: "transparent" }} />
              <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_today")}</TableCell>
              <TableCell sx={{ background: "transparent", textAlign: "right" }}>{t("device_live_all")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align={"right"}>{t("device_live_cycles")}</TableCell>
              <TableCell align={"right"}>{cycle}</TableCell>
              <TableCell align={"right"}>{allCycle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align={"right"}>{t("device_live_detections")}</TableCell>
              <TableCell align={"right"}>{recoilToday}</TableCell>
              <TableCell align={"right"}>{allRecoil}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
export default memo(LiveStatusView);
