import React from "react";
import {Navigate, Outlet, Route} from "react-router-dom";

export const PublicRoute =  ({isLogged}) => {
    return (
        <>
            {!isLogged ? <Outlet/> : <Navigate to={"/"}/>}
        </>
    );
}



