import { Avatar, Stack, Tooltip } from "@mui/material";

import TableCell from "@mui/material/TableCell";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageIcon from "@mui/icons-material/Image";

const placeholder = "/time.png";

const DevicesPreviewTableCell = ({ handleLiveView, deviceId, lastImg }) => {
  const { t } = useTranslation("common");

  const [img, setImg] = useState(lastImg);

  const handleError = (e) => {
    e.target.error = null;
    setImg(placeholder);

    if (lastImg !== null) {
      setTimeout(setOriginalImage, 12000, e);
    }
  };

  const setOriginalImage = () => {
    setImg(lastImg);
  };

  return (
    <TableCell align="left">
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Avatar
          src={img}
          alt={`img ${deviceId}`}
          sx={{ width: 64, height: 64, background: "black" }}
          variant="square"
          onError={handleError}
          onClick={handleLiveView}
        >
          <ImageIcon sx={{ color: color.darkGray }} />
        </Avatar>
        <Tooltip title={t("table_action_cell_preview")} placement="bottom" arrow={true}>
          <IconButton size="small" onClick={handleLiveView}>
            <SearchIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  );
};
export default memo(DevicesPreviewTableCell);
