import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp";
import * as color from "assets/styles/jss/common/colors";
import { memo } from "react";

const ChipStatus = ({ value }) => {
  return value ? (
    <CheckCircleOutlineSharpIcon sx={{ color: color.green }} />
  ) : (
    <HighlightOffIcon sx={{ color: color.red }} />
  );
};
export default memo(ChipStatus);
