import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import * as Yup from "yup";
import { ipv4 } from "utils/ipv4validation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.DEVICES;

const AllDevicesEdit = () => {
  const { t } = useTranslation("common");
  const [halls, setHalls] = useState("");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  Yup.addMethod(Yup.string, "ipv4", ipv4);
  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("form-validation-required")),
    ip: Yup.string().ipv4().trim().required(t("form-validation-required")),
    hall: Yup.string().trim().required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data.item;

        for (const dataKey in item) {
          methods.setValue(dataKey, item[dataKey]);
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  useEffect(() => {
    backend.get(EXTERNAL_API.HALLS_ALL).then((res) => {
      if (isSuccess(res)) {
        setHalls(
          res.data.map((item) => {
            return {
              id: item.id,
              displayName: item.name,
            };
          }),
        );
      }
    });
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const lastModification = methods.watch("lastModification");

  const onSubmit = (formData) => {
    backend.post(endpoint + id, formData).then((res) => {
      if (isSuccess(res)) {
        console.log(res);
        methods.setValue("lastModification", res.data.item["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedTextField label={t("device_new_name")} name={"name"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedTextField label={t("device_new_ip")} name={"ip"} />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect label={t("device_new_hall")} name={"hall"} items={halls} />
          </Grid>
          {lastModification && (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lastModification}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="small">
              {t("button_save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default AllDevicesEdit;
