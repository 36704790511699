import TableCell from "@mui/material/TableCell";
import { TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const RowNoData = ({ colSpan }) => {
  const { t } = useTranslation("common");
  return (
    <TableRow>
      <TableCell align={"center"} colSpan={colSpan}>
        <Typography>{t("table_no_data")}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default RowNoData;
