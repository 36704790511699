import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import * as color from "assets/styles/jss/common/colors";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useCallback, useEffect, useState } from "react";

const LiveTab = ({ hall, handleChangeSelectedHalls, selectedHalls }) => {
  const { t } = useTranslation("common");

  const [isSelected, setIsSelected] = useState(false);

  const checkIsSelected = useCallback(
    (id) => {
      return selectedHalls.includes(id);
    },
    [selectedHalls],
  );

  useEffect(() => {
    setIsSelected(checkIsSelected(hall.id));
  }, [checkIsSelected]);

  return (
    <Button
      size="small"
      onClick={() => handleChangeSelectedHalls(hall.id, isSelected)}
      disabled={!hall.isWorking}
      variant={isSelected ? "contained" : "outlined"}
    >
      {!isSelected ? (
        <CheckBoxOutlineBlankIcon fontSize={"small"} sx={{ marginRight: 1, color: color.primary }} />
      ) : (
        <CheckBoxIcon fontSize={"small"} sx={{ marginRight: 1, color: color.lightGray }} />
      )}
      {hall.name}
    </Button>
  );
};
export default LiveTab;
