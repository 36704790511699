import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedTextField from "components/Edit/CustomizedTextField";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.SETTINGS_SCANNER;
const AllDevicesEditScannerSettings = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const selectValue = [
    { id: "1", displayName: t("device_edit_scanner_integration_normal") },
    { id: "2", displayName: t("device_edit_scanner_integration_constant") },
    { id: "3", displayName: t("device_edit_scanner_integration_mode_virtual") },
  ];

  const validationSchema = Yup.object({
    scanningSpeed: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
    integrationTime: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
    signalSensitivity: Yup.number()
      .typeError(t("form-validation-number"))
      .positive(t("form-validation-number"))
      .required(t("form-validation-required")),
    integrationMode: Yup.string().trim().required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data;

        for (const dataKey in item) {
          methods.setValue(dataKey, item[dataKey]);
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    backend.post(endpoint + id, formData).then((res) => {
      if (isSuccess(res)) {
        methods.setValue("lastModification", res.data["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const lastModification = methods.watch("lastModification");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CustomizedTextField
              name="scanningSpeed"
              label={t("device_edit_scanning_speed")}
              type="number"
              defaultValue={"400"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedTextField
              name="signalSensitivity"
              label={t("device_edit_signal_sensitivity")}
              type="number"
              defaultValue={"7"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedTextField
              name="integrationTime"
              label={t("device_edit_integration_time")}
              type="number"
              defaultValue={"540"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomizedSelect
              label={t("device_edit_integration_mode")}
              name="integrationMode"
              items={selectValue}
              defaultValue="3"
              noNullValue
            />
          </Grid>
          {lastModification && (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lastModification}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button variant="contained" size="small" type="submit">
              {t("button_save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default AllDevicesEditScannerSettings;
