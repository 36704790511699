const headerStyles = {
  header: {
    padding: "4vh 4vh 2vh 4vh",
    "@media (max-width: 960px)": {
      padding: "2vh",
    },
  },
  upperSection: {
    display: "flex",
  },
  label: {
    padding: "2vh 0 1vh 0",
  },
  divider: {
    margin: "7px 15px 7px 23px !important",
  },
  actions: {},
  actionIcon: {},
  actionButton: {
    fontSize: "0.95rem",
  },
};

export default headerStyles;
