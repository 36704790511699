import { Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedCheckbox from "components/Edit/CustomizedCheckbox";
import CustomizedTimePicker from "components/Edit/CustomizedTimePicker";
import { format } from "date-fns";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const week = [
  { short: "Mon.", long: "Monday" },
  { short: "Tues", long: "Tuesday" },
  { short: "Wed.", long: "Wednesday" },
  { short: "Thurs.", long: "Thursday" },
  { short: "Fri.", long: "Friday" },
  { short: "Sat.", long: "Saturday" },
  { short: "Sun.", long: "Sunday" },
];

const endpoint = EXTERNAL_API.SETTINGS_TIME_WINDOWS;

const AllDevicesEditTimeWindow = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    windowStatus: Yup.bool().required(t("form-validation-required")),
    windowFromMonday: Yup.date().when("windowStatusMonday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToMonday: Yup.date().when("windowStatusMonday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusMonday: Yup.bool().required(t("form-validation-required")),
    windowFromTuesday: Yup.date().when("windowStatusTuesday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToTuesday: Yup.date().when("windowStatusTuesday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusTuesday: Yup.bool().required(t("form-validation-required")),
    windowFromWednesday: Yup.date().when("windowStatusWednesday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToWednesday: Yup.date().when("windowStatusWednesday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusWednesday: Yup.bool().required(t("form-validation-required")),
    windowFromThursday: Yup.date().when("windowStatusThursday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToThursday: Yup.date().when("windowStatusThursday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusThursday: Yup.bool().required(t("form-validation-required")),
    windowFromFriday: Yup.date().when("windowStatusFriday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToFriday: Yup.date().when("windowStatusFriday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusFriday: Yup.bool().required(t("form-validation-required")),
    windowFromSaturday: Yup.date().when("windowStatusSaturday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToSaturday: Yup.date().when("windowStatusSaturday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusSaturday: Yup.bool().required(t("form-validation-required")),
    windowFromSunday: Yup.date().when("windowStatusSunday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowToSunday: Yup.date().when("windowStatusSunday", {
      is: true,
      then: (validationSchema) =>
        validationSchema.when("windowStatus", {
          is: true,
          then: (validationSchemaX) =>
            validationSchemaX.typeError(t("form-validation-wrong-time")).required(t("form-validation-wrong-time")),
          otherwise: (validationSchema) => validationSchema.nullable(),
        }),
      otherwise: (validationSchema) => validationSchema.nullable(),
    }),
    windowStatusSunday: Yup.bool().required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data;

        const fromFieldName = week.map((day) => `windowFrom${day.long}`);
        const toFieldName = week.map((day) => `windowTo${day.long}`);

        for (const dataKey in item) {
          if (fromFieldName.includes(dataKey) || toFieldName.includes(dataKey)) {
            if (item[dataKey] && item[dataKey].length > 0) {
              const hhmm = item[dataKey].split(":");
              if (hhmm.length === 2) {
                methods.setValue(dataKey, new Date(0, 0, 0, hhmm[0], hhmm[1]));
              }
            }
          } else {
            methods.setValue(dataKey, item[dataKey]);
          }
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    const dataToSend = { ...formData };

    for (const day of week) {
      if (dataToSend[`windowFrom${day.long}`]) {
        dataToSend[`windowFrom${day.long}`] = format(dataToSend[`windowFrom${day.long}`], "HH:mm");
      }

      if (dataToSend[`windowTo${day.long}`]) {
        dataToSend[`windowTo${day.long}`] = format(dataToSend[`windowTo${day.long}`], "HH:mm");
      }
    }

    backend.post(endpoint + id, dataToSend).then((res) => {
      if (isSuccess(res)) {
        methods.setValue("lastModification", res.data["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const windowStatus = methods.watch("windowStatus");
  const lastModification = methods.watch("lastModification");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedCheckbox name="windowStatus" label={t("device_edit_time_window")} />
          </Grid>
          {week.map((day) => (
            <Fragment key={day.long}>
              <Grid item xs={3}>
                <CustomizedCheckbox name={`windowStatus${day.long}`} label={day.short} disabled={!windowStatus} />
              </Grid>
              <Grid item xs={4}>
                <CustomizedTimePicker
                  name={`windowFrom${day.long}`}
                  defaultValue={new Date(0, 0, 0, 5, 0)}
                  disabled={!windowStatus || !methods.watch(`windowStatus${day.long}`)}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomizedTimePicker
                  name={`windowTo${day.long}`}
                  defaultValue={new Date(0, 0, 0, 23, 0)}
                  disabled={!windowStatus || !methods.watch(`windowStatus${day.long}`)}
                />
              </Grid>
            </Fragment>
          ))}
          {lastModification && (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lastModification}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="small">
              {t("button_save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default AllDevicesEditTimeWindow;
