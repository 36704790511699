import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import backend from "api/backend";

export const fetchMaterialList = createAsyncThunk("material/list", async (data, thunkAPI) => {
  const res = await backend.get("/material/");
  return res.data;
});

const materialsSlice = createSlice({
  name: "materials",
  initialState: {
    isLoading: false,
    isDialogOpen: false,
    isCloneOrCopyButtonDisabled: true,
    isListDeleteDialogOpen: false,
    isListCloneDialogOpen: false,
    isLiveCommunicationOk: false,
    list: [],
  },
  reducers: {
    materialDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;
    },
    setCloneOrCopyButtonDisabled: (state, action) => {
      state.isCloneOrCopyButtonDisabled = action.payload;
    },
    setListDeleteDialogOpen: (state, action) => {
      state.isListDeleteDialogOpen = action.payload;
    },
    setListCloneDialogOpen: (state, action) => {
      state.isListCloneDialogOpen = action.payload;
    },
    setLiveCommunicationOk: (state, action) => {
      state.isLiveCommunicationOk = action.payload;
    },
  },
  extraReducers: {
    [fetchMaterialList.pending]: (state, action) => {
      state.isLoading = true;
    },
    [fetchMaterialList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [fetchMaterialList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.list = action.payload;
    },
  },
});

export const selectMaterialList = (state) => state.materials.list;
export const selectMaterialDialogOpen = (state) => state.materials.isDialogOpen;
export const selectCloneOrCopyButtonDisabled = (state) => state.materials.isCloneOrCopyButtonDisabled;
export const selectListDeleteDialogOpen = (state) => state.materials.isListDeleteDialogOpen;
export const selectListCloneDialogOpen = (state) => state.materials.isListCloneDialogOpen;
export const selectIsLiveCommunicationOk = (state) => state.materials.isLiveCommunicationOk;

export const {
  materialDialogOpen,
  setCloneOrCopyButtonDisabled,
  setListDeleteDialogOpen,
  setListCloneDialogOpen,
  setLiveCommunicationOk,
} = materialsSlice.actions;

export default materialsSlice.reducer;
