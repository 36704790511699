import { configureStore } from "@reduxjs/toolkit";
import crud from "./slices/crud";
import groups from "./slices/groups";
import lockInfo from "./slices/lockInfo";
import materials from "./slices/materials";

import theme from "./slices/theme";
import user from "./slices/user";
import workers from "./slices/workers";
import menuSlices from "./slices/menu";

export default configureStore({
  reducer: {
    theme: theme,
    user: user,
    crud: crud,
    groups: groups,
    materials: materials,
    workers: workers,
    lockInfo: lockInfo,
    menu: menuSlices,
  },
  devTools: process.env.NODE_ENV !== 'production',
});