export const INTERNAL_API = {
  MAIN: "/",
  LOGIN: "/login",
  LIVE: "/live",
  LIVE_DEVICE: "/live/device",
  BROWSER: "/detections",
  DEVICE: "/devices",
  DEVICE_SETTINGS: "/devices/edit",
  HALLS: "/halls",
  VALIDATION_SCENARIOS: "/validation/scenarios",
  VALIDATION_RECOIL: "/validation/rejection",
  VALIDATION_LAMP: "/validation/lamp",
  VALIDATION_DETECTION: "/validation/detection",
  REPORT_DAILY: "/report/daily",
  REPORT_PERIODIC: "/report/periodic",
  REPORT_VALIDATION: "/report/validation",
  TICKET: "/ticket/submit",
  TICKET_LOG: "/ticket/log",
  MY_PROFILE: "/settings/profile",
  USERS: "/settings/users",
  ROLES: "/settings/roles",
  CHANGE_PASSWORD: "/settings/change-password",
  ACTIVITY: "/settings/activity",
  TIME_WINDOW: "/settings/time-window",
  SETTINGS_RECOIL: "/settings/recoil",
  SCANNER: "/settings/scanner",
  DETECTION: "/settings/detection",
  SAVE_IMAGE: "/settings/save-image",
  APP_SETTINGS: "/settings/app",
  LOGIN_LOG: "/settings/login-log",
};
