import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CustomizedSelect from "components/Edit/CustomizedSelect";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.SETTINGS_REJECT_SIGNAL;

const AllDevicesEditRejectSignal = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const selectIsRecoil = [
    { id: false, displayName: t("device_edit_reject_signal_off") },
    { id: true, displayName: t("device_edit_reject_signal_on") },
  ];

  const selectIsRecoilDouble = [
    { id: false, displayName: t("device_edit_reject_signal_double") },
    { id: true, displayName: t("device_edit_reject_signal_single") },
  ];

  const selectFirstSecondRecoil = [
    { id: "1", displayName: "1" },
    { id: "2", displayName: "2" },
    { id: "3", displayName: "3" },
    { id: "4", displayName: "4" },
    { id: "5", displayName: "5" },
    { id: "6", displayName: "6" },
    { id: "7", displayName: "7" },
    { id: "8", displayName: "8" },
  ];

  const validationSchema = Yup.object({
    isRecoil: Yup.boolean().required(t("form-validation-required")),
    isRecoilDouble: Yup.boolean().required(t("form-validation-required")),
    firstRecoil: Yup.string().trim().required(t("form-validation-required")),
    secondRecoil: Yup.string().trim().required(t("form-validation-required")),
  });

  useEffect(() => {
    backend.get(endpoint + id).then((response) => {
      if (isSuccess(response)) {
        const item = response.data;

        for (const dataKey in item) {
          methods.setValue(dataKey, item[dataKey]);
        }
      } else {
        console.error(response);
      }
    });
  }, []);

  const methods = useForm({
    criteriaMode: "all",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    backend.post(endpoint + id, formData).then((res) => {
      if (isSuccess(res)) {
        methods.setValue("lastModification", res.data["lastModification"]);

        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const lastModification = methods.watch("lastModification");

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_reject_signal")}
              name="isRecoil"
              items={selectIsRecoil}
              defaultValue={"false"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_type_of_rejection_signal")}
              name="isRecoilDouble"
              items={selectIsRecoilDouble}
              defaultValue={"false"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_first_rejection_in_the_cycle")}
              name="firstRecoil"
              items={selectFirstSecondRecoil}
              defaultValue={"1"}
              noNullValue
            />
          </Grid>
          <Grid item xs={12}>
            <CustomizedSelect
              label={t("device_edit_second_rejection_in_the_cycle")}
              name="secondRecoil"
              items={selectFirstSecondRecoil}
              defaultValue={"1"}
              noNullValue
            />
          </Grid>
          {lastModification && (
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lastModification}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" size="small">
              {t("button_save")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
export default AllDevicesEditRejectSignal;
