const csvGenerator = (data) => {
  let csvContent = "";
  const filename = `report-${data?.creationDate.replaceAll(" ", "-").replaceAll(":", "-")}.csv`;
  const separator = ",";
  const newLine = "\n";

  const fields = ["deviceName", "cycleOn", "cycleOff", "cycleAll", "recoils", "restartsAll"]


  const list = [...data.reportItemsDetectionOn, ...data.reportItemsDetectionOff];
  const sortedList = list.sort((a, b) => a.deviceName.localeCompare(b.deviceName));

  csvContent += fields.join(separator) + newLine;

  for (const item of sortedList) {
    for (const field of fields) {
      csvContent += item[field] + separator;
    }
    csvContent += newLine;
  }

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) { // In case of IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
export default csvGenerator