import React from "react";
import { ImageList } from "@mui/material";
import LiveGalleryImg from "modules/LiveDevice/LiveDeviceComponent/LiveGalleryImg";

const LiveGalleryView = ({ beforeImage }) => {
  return (
    <ImageList cols={6}>
      {beforeImage?.map((item) => (
        <LiveGalleryImg name={item.name} image={item.image} time={item.time} key={item.name} />
      ))}
    </ImageList>
  );
};
export default LiveGalleryView;
