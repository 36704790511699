import React, { memo } from "react";
import TextField from "@mui/material/TextField";
import { Grid, InputLabel } from "@mui/material";
import { useFormContext } from "react-hook-form";

const CustomizedTextField = ({
  label,
  name,
  disabled = false,
  type = "text",
  defaultValue = "",
  step = 1,
  multiline,
  minRows,
  className,
}) => {
  const { register, formState } = useFormContext();

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <TextField
        className={className}
        {...register(name)}
        helperText={formState?.errors[name] ? formState?.errors[name].message : ""}
        error={!!formState?.errors[name]}
        variant="outlined"
        size="small"
        fullWidth={true}
        disabled={disabled}
        type={type}
        inputProps={type === "number" ? { min: 1, step: step } : {}}
        defaultValue={defaultValue}
        multiline={multiline}
        minRows={minRows}
      />
    </>
  );
};

export default memo(CustomizedTextField);
