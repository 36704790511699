import LensSharpIcon from "@mui/icons-material/LensSharp";
import { useTranslation } from "react-i18next";
import { Box, Stack, Tooltip } from "@mui/material";
import { selectIsLiveCommunicationOk } from "redux/slices/materials";
import { useSelector } from "react-redux";
import { memo } from "react";

const LiveTitle = ({ title }) => {
  const { t } = useTranslation("common");
  const isCommunicationOk = useSelector(selectIsLiveCommunicationOk);

  const okColor = "#ff1100";
  const warringColor = "#ff6600";

  return (
    <Stack direction="row" justifyContent="left" alignItems="center" spacing={2}>
      <Box>{title}</Box>
      {isCommunicationOk ? (
        <LensSharpIcon className="blinking" sx={{ fontSize: "1.5rem", color: okColor }} />
      ) : (
        <Tooltip title={t("live_communication_error")}>
          <LensSharpIcon sx={{ fontSize: "1.5rem", color: warringColor }} />
        </Tooltip>
      )}
    </Stack>
  );
};

export default memo(LiveTitle);
