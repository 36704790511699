import TableCell from "@mui/material/TableCell";
import * as color from "assets/styles/jss/common/colors";
import { alpha } from "@mui/material/styles";
import { Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTranslation } from "react-i18next";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const CellActions = ({ row, handleAction }) => {
  const { t } = useTranslation("common");
  return (
    <TableCell>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Tooltip title={t("table_action_cell_accept")} placement="bottom" arrow={true}>
          <IconButton size="small" onClick={() => handleAction(row, "ACCEPTED")}>
            <CheckCircleOutlineIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("table_action_cell_discard")} placement="bottom" arrow={true}>
          <IconButton size="small" onClick={() => handleAction(row, "REJECTED")}>
            <HighlightOffIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("table_action_cell_tester")} placement="bottom" arrow={true}>
          <IconButton size="small" onClick={() => handleAction(row, "TEST")}>
            <RadioButtonCheckedIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </TableCell>
  );
};

export default CellActions;
