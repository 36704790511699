import { Box, Button, Grid, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React, { useState } from "react";
import * as color from "assets/styles/jss/common/colors";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import TableSortLabel from "@mui/material/TableSortLabel";
import PaginationTable from "modules/Devices/AllDeviceDetails/PaginationTable";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const example = {
  results: [
    {
      id: 0,
      date: "01.04.2023 12:06 ",
      deviceName: "S1",
      cycles: 99,
      pauses: 10,
      rate: "",
      signal: 1,
    },
    {
      id: 0,
      date: "02.04.2023 12:06 ",
      deviceName: "S2",
      cycles: 66,
      pauses: 20,
      rate: "",
      signal: 1,
    },
    {
      id: 0,
      date: "02.04.2023 12:06 ",
      deviceName: "D1",
      cycles: 22,
      pauses: 15,
      rate: "",
      signal: 3,
    },
    {
      id: 0,
      date: "03.04.2023 11:06 ",
      deviceName: "D2",
      cycles: 30,
      pauses: 10,
      rate: "",
      signal: 1,
    },
    {
      id: 0,
      date: "04.04.2023 14:06 ",
      deviceName: "D3",
      cycles: 50,
      pauses: 5,
      rate: "",
      signal: 2,
    },
    {
      id: 0,
      date: "05.04.2023 13:06 ",
      deviceName: "D4",
      cycles: 60,
      pauses: 4,
      rate: "",
      signal: 3,
    },
    {
      id: 0,
      date: "06.04.2023 09:06 ",
      deviceName: "D5",
      cycles: 80,
      pauses: 60,
      rate: "",
      signal: 3,
    },
    {
      id: 0,
      date: "07.04.2023 10:06 ",
      deviceName: "D6",
      cycles: 90,
      pauses: 80,
      rate: "",
      signal: 1,
    },
    {
      id: 0,
      date: "08.04.2023 12:09 ",
      deviceName: "D7",
      cycles: 80,
      pauses: 60,
      rate: "",
      signal: 3,
    },
    {
      id: 0,
      date: "09.04.2023 16:01 ",
      deviceName: "F16",
      cycles: 100,
      pauses: 90,
      rate: "",
      signal: 2,
    },
  ],
};

const ResultLampValidation = () => {
  const { t } = useTranslation("common");
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");

  const handleSort = (columnName) => {
    if (orderBy === columnName) {
      setOrder((prev) => (prev === "desc" ? "asc" : "desc"));
    } else {
      setOrderBy(columnName);
      setOrder("desc");
    }
  };

  const handleAction = (result, action) => {};

  return (
    <Paper sx={{ p: 10 }} elevation={8}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "date"}
                      direction={orderBy === "date" ? order : "asc"}
                      onClick={() => handleSort("date")}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ paddingLeft: "0px" }}>
                    <TableSortLabel
                      active={orderBy === "device"}
                      direction={orderBy === "device" ? order : "asc"}
                      onClick={() => handleSort("device")}
                    >
                      Device
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ textAlign: "right", paddingLeft: "0px" }}>
                    <TableSortLabel
                      active={orderBy === "cycles"}
                      direction={orderBy === "cycles" ? order : "asc"}
                      onClick={() => handleSort("cycles")}
                    >
                      Repetitions
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", paddingLeft: "0px" }}>
                    <TableSortLabel
                      active={orderBy === "signal"}
                      direction={orderBy === "signal" ? order : "asc"}
                      onClick={() => handleSort("signal")}
                    >
                      Signal
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", paddingLeft: "0px" }}>Comment</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {example.results.map((result) => (
                  <TableRow key={result.id}>
                    <TableCell>
                      <PictureAsPdfIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>{result.date}</TableCell>
                    <TableCell sx={{ paddingLeft: "0px" }} className="devices-font">
                      {result.deviceName}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right", paddingLeft: "0px", paddingRight: "45px" }}>
                      {result.cycles}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", paddingLeft: "0px", paddingRight: "45px" }}>
                      <Stack direction="row" justifyContent="right" alignItems="center" spacing={2}>
                        {result.signal === 2 || result.signal === 3 ? (
                          <Tooltip title="Sound" placement="bottom" arrow={true}>
                            <VolumeUpIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Sound Off" placement="bottom" arrow={true}>
                            <VolumeUpIcon fontSize="small" sx={{ color: alpha(color.black, 0.1) }} />
                          </Tooltip>
                        )}
                        {result.signal === 1 || result.signal === 3 ? (
                          <Tooltip title="Flash On" placement="bottom" arrow={true}>
                            <FlashOnIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Flash Off" placement="bottom" arrow={true}>
                            <FlashOnIcon fontSize="small" sx={{ color: alpha(color.black, 0.1) }} />
                          </Tooltip>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ textAlign: "center", paddingLeft: "0px" }}>
                      <Tooltip title={"Edit comment"} placement="bottom" arrow={true}>
                        <IconButton size="small" onClick={() => handleAction(result, "ADD_COMMENT")}>
                          <AddCircleOutlineIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                        <Tooltip title={t("table_action_cell_remove")} placement="bottom" arrow={true}>
                          <IconButton size="small" onClick={() => handleAction(result, "REMOVE")}>
                            <DeleteIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                          </IconButton>
                        </Tooltip>
                        <Button
                          sx={{
                            paddingY: "4px!important",
                            paddingX: "0px!important",
                            borderRadius: "8px",
                            fontSize: "0.45rem",
                          }}
                          variant="contained"
                          size="small"
                          onClick={() => handleAction(result, "DOWNLOAD")}
                        >
                          {t("table_action_cell_download")}
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="right">
            <PaginationTable
              maxPages={100}
              actualPage={1}
              itemPerPage={20}
              setActualPage={() => {}}
              setItemPerPage={() => {}}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default ResultLampValidation;
