import { Grid, LinearProgress, Table } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import DevicesStatusTableRow from "modules/Live/Live/DevicesStatusTableRow";
import { setLiveCommunicationOk } from "redux/slices/materials";
import { useDispatch } from "react-redux";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const endpoint = EXTERNAL_API.LIVE_DEVICES;

const DevicesStatus = ({ selectedHalls }) => {
  const { t } = useTranslation("common");
  const [statusDevices, setStatusDevices] = useState([]);
  const [firstGetDataIsProgress, setFirstGetDataIsProgress] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 15000);
    return () => clearInterval(interval);
  }, [selectedHalls]);

  const getData = () => {
    if (selectedHalls.length > 0) {
      backend
        .post(endpoint, selectedHalls)
        .then((response) => {
          if (isSuccess(response)) {
            setStatusDevices(response.data);
            dispatch(setLiveCommunicationOk(true));
          } else {
            dispatch(setLiveCommunicationOk(false));
            console.error(response);
          }
        })
        .catch((err) => {
          dispatch(setLiveCommunicationOk(false));
          console.error(err);
        })
        .finally(() => {
          setFirstGetDataIsProgress(false);
        });
    }
  };

  const handleLiveView = useCallback((id) => {
    console.log("handleLiveView");
    console.log(id);
  }, []);

  return (
    <Grid container spacing={5}>
      {firstGetDataIsProgress ? (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TableContainer
            sx={{
              ".MuiTableRow-root": {
                height: "75px",
              },
            }}
          >
            <Table aria-label="customized table" sx={{ width: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ textAlign: "left", paddingX: 2 }}>{t("live_table_production_line")}</TableCell>
                  <TableCell sx={{ textAlign: "left", paddingX: 2, width: "5%" }} colSpan={2}>
                    {t("live_table_cycles")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right", paddingX: 2 }}>{t("live_table_detections")}</TableCell>
                  <TableCell sx={{ textAlign: "center", paddingX: 2 }}>{t("live_table_last_on")}</TableCell>
                  <TableCell sx={{ textAlign: "center", paddingX: 2, width: "7%" }}>
                    {t("live_table_reject_signal")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", paddingX: 2, width: "7%" }}>{t("live_table_power")}</TableCell>
                  <TableCell sx={{ textAlign: "center", paddingX: 2, width: "7%" }}>
                    {t("live_table_detection")}
                  </TableCell>
                  <TableCell sx={{ textAlign: "right", paddingX: 2 }}>{t("live_table_count")}</TableCell>
                  <TableCell sx={{ textAlign: "left", paddingX: 2, width: "7%" }}>
                    {t("live_table_live_preview")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusDevices.map((device) => (
                  <DevicesStatusTableRow
                    key={device.deviceId}
                    deviceId={device.deviceId}
                    handleLiveView={handleLiveView}
                    recoilToday={device.recoilToday}
                    restarts={device.restarts}
                    status={device.status}
                    isRecoil={device.isRecoil}
                    tegra={device.tegra}
                    time={device.time}
                    cycle={device.cycle}
                    name={device.name}
                    lastWeekLabels={device.lastWeekLabels}
                    lastWeekCycles={device.lastWeekCycles}
                    lastImg={device.lastImg}
                    uuid={device.uuid}
                  />
                ))}
                <TableRow sx={{ height: "40px!important" }} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};
export default DevicesStatus;
