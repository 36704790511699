import { Grid, LinearProgress, Tooltip } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import { alpha } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import PaginationTable from "modules/Devices/AllDeviceDetails/PaginationTable";
import TableSortLabel from "@mui/material/TableSortLabel";
import backend from "api/backend";
import { isSuccess } from "utils/http";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CellMiniature from "components/Table/CellMiniature";
import * as color from "assets/styles/jss/common/colors";
import CellImageStatus from "components/Table/CellImageStatus";
import { EXTERNAL_API } from "enums/EXTERNAL_API";

const ImagePreviewDialog = React.lazy(() => import("components/ImagePreviewDialog"));

const endpoint = EXTERNAL_API.IMAGES;
const AllDevicesDetailGetImages = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [images, setImages] = useState([]);
  const [isGetDataProgress, setIsGetDataProgress] = useState(true);

  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("desc");
  const [maxPages, setMaxPages] = useState(1);
  const [actualPage, setActualPage] = useState(1);
  const itemPerPage = 10;

  const [imagePreviewDialogOpen, setImagePreviewDialogOpen] = useState(false);
  const [previewedImage, setPreviewedImage] = useState(null);

  const handleSort = (columnName) => {
    if (orderBy === columnName) {
      setOrder((prev) => (prev === "desc" ? "asc" : "desc"));
    } else {
      setOrderBy(columnName);
      setOrder("desc");
    }
  };

  useEffect(() => {
    getData();
  }, [actualPage, orderBy, order]);

  const getData = () => {
    setIsGetDataProgress(true);

    let url = endpoint;
    url += "?page=" + (actualPage - 1);
    url += "&limit=" + itemPerPage;
    url += "&order=" + orderBy;
    url += order === "desc" ? "%2Bdesc" : "";
    url += "&filter%5Bdevice%5D=" + id;

    backend
      .get(url)
      .then((response) => {
        if (isSuccess(response)) {
          setImages(response.data.items);
          setMaxPages(response.data.pagination.totalPages);
        } else {
          console.error(response);
        }
        setIsGetDataProgress(false);
      })
      .catch((err) => {
        console.error(err);
        setIsGetDataProgress(false);
      });
  };

  const handleSetActualPage = (page) => {
    setActualPage(page);
  };

  const handleImageAction = (row, action) => {
    const data = {
      id: row.id,
      type: action,
    };

    backend.post(endpoint + "type", data).then((res) => {
      if (isSuccess(res)) {
        enqueueSnackbar(t("common_status_successfully"), {
          variant: "success",
        });
        getData();
      } else {
        enqueueSnackbar(t("common_status_error") + res.status + "/" + res.message, {
          variant: "error",
        });
      }
    });
  };

  const handleImagePreview = (image) => {
    setPreviewedImage(image);
    setImagePreviewDialogOpen(true);
  };

  const handleImagePreviewClosed = (isActionDone) => {
    if (isActionDone) {
      getData();
    }
    setPreviewedImage(null);
    setImagePreviewDialogOpen(false);
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {isGetDataProgress ? (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell id="name" sortDirection={orderBy === "name" ? order : false}>
                        <TableSortLabel
                          active={orderBy === "name"}
                          direction={orderBy === "name" ? order : "asc"}
                          onClick={() => handleSort("name")}
                        >
                          {t("browser_table_name")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell id="type" sortDirection={orderBy === "type" ? order : false}>
                        <TableSortLabel
                          active={orderBy === "type"}
                          direction={orderBy === "type" ? order : "asc"}
                          onClick={() => handleSort("type")}
                        >
                          {t("browser_table_type")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell id="status" sortDirection={orderBy === "status" ? order : false}>
                        <TableSortLabel
                          active={orderBy === "status"}
                          direction={orderBy === "status" ? order : "asc"}
                          onClick={() => handleSort("status")}
                        >
                          {t("browser_table_status")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell id="datetime" sortDirection={orderBy === "datetime" ? order : false}>
                        <TableSortLabel
                          active={orderBy === "datetime"}
                          direction={orderBy === "datetime" ? order : "asc"}
                          onClick={() => handleSort("datetime")}
                        >
                          {t("browser_table_date")}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }} id="actions">
                        {t("browser_table_device_action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {images.map((image) => (
                      <TableRow key={image.id} hover>
                        <CellMiniature data={image.image} handlePreview={handleImagePreview} row={image} />
                        <TableCell>{image.name}</TableCell>
                        <CellImageStatus data={image.type} />
                        <TableCell>{image.status}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{image.datetime}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                          <Tooltip
                            title={t("table_action_cell_accept")}
                            placement="bottom"
                            arrow={true}
                            sx={{ marginRight: "10px" }}
                          >
                            <IconButton size="small" onClick={() => handleImageAction(image, "ACCEPTED")}>
                              <CheckCircleOutlineIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={t("table_action_cell_discard")}
                            placement="bottom"
                            arrow={true}
                            sx={{ marginRight: "10px" }}
                          >
                            <IconButton size="small" onClick={() => handleImageAction(image, "REJECTED")}>
                              <HighlightOffIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={t("table_action_cell_tester")}
                            placement="bottom"
                            arrow={true}
                            sx={{ marginRight: "10px" }}
                          >
                            <IconButton size="small" onClick={() => handleImageAction(image, "TEST")}>
                              <RadioButtonCheckedIcon fontSize="small" sx={{ color: alpha(color.black, 0.7) }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                    {images?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={6}>{t("table_no_data")}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              {images?.length > 0 && (
                <PaginationTable
                  maxPages={maxPages}
                  actualPage={actualPage}
                  itemPerPage={itemPerPage}
                  setActualPage={handleSetActualPage}
                  setItemPerPage={() => {}}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      {imagePreviewDialogOpen && (
        <Suspense>
          <ImagePreviewDialog data={previewedImage} open={imagePreviewDialogOpen} close={handleImagePreviewClosed} />
        </Suspense>
      )}
    </>
  );
};
export default AllDevicesDetailGetImages;
