export default function getBackendHost() {
  const hostname = window && window.location && window.location.hostname;
  if (hostname === "x-ray.firecrux.com") {
    return "https://x-ray.firecrux.com/api";
  }
  if (hostname === "panel.firecrux.com") {
    return "https://panel.firecrux.com/api";
  }
  if (hostname === "10.10.11.89") {
    return "http://10.10.11.89:82/api";
  } else {
    return "http://localhost:8080";
  }
}
