import { Breadcrumbs, Link } from "@mui/material";
import { black, darkGray } from "assets/styles/jss/common/colors";
import React, { memo } from "react";

const HeaderBreadcrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" separator={">"}>
      {breadcrumbs?.map((bread, index) => (
        <Link
          key={index}
          underline={!bread.href ? "none" : "hover"}
          color={!bread.href ? black : darkGray}
          href={!bread.href ? null : bread.href}
        >
          {bread.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default memo(HeaderBreadcrumbs);
