import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MiniChart from "modules/Live/Live/MiniChart";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import DevicesPreviewTableCell from "modules/Live/Live/DevicesPreviewTableCell";
import DevicesStatisticTableCells from "modules/Live/Live/DevicesStaticsticTableCells";
import { Link } from "@mui/material";
import { INTERNAL_API } from "../../../enums/INTERNAL_API";
import * as color from "assets/styles/jss/common/colors";

const DevicesStatusTableRow = ({
  handleLiveView,
  deviceId,
  recoilToday,
  restarts,
  status,
  isRecoil,
  tegra,
  time,
  cycle,
  name,
  lastWeekLabels,
  lastWeekCycles,
  lastImg,
  uuid,
}) => {
  const [lastStateDetections, setLastStateDetections] = useState("NOT_INIT");
  const ref = useRef();

  useEffect(() => {
    if (lastStateDetections === "NOT_INIT") {
      setLastStateDetections(recoilToday);
      return;
    }

    if (ref.current != null && lastStateDetections !== recoilToday) {
      const row = ref.current;
      row.classList.add("flash");
    }

    setLastStateDetections(recoilToday);
  }, [recoilToday]);

  useEffect(() => {
    if (ref.current != null && lastStateDetections !== recoilToday) {
      const row = ref.current;
      row.addEventListener("animationend", () => {
        row.classList.remove("flash");
      });
    }
  }, [ref]);

  const handleClickView = useCallback(() => {
    handleLiveView(deviceId);
  }, [deviceId, handleLiveView]);

  return (
    <TableRow ref={ref} className="" hover>
      <TableCell align="left" className="devices-font" sx={{ paddingX: 2 }}>
        <Link
          href={`${INTERNAL_API.LIVE_DEVICE}/${uuid}`}
          sx={{ color: color.black, textDecorationColor: color.black }}
        >
          {name}
        </Link>
      </TableCell>
      <TableCell align="left" sx={{ paddingX: 2 }}>
        <MiniChart x={lastWeekLabels} y={lastWeekCycles} />
      </TableCell>
      <DevicesStatisticTableCells
        isRecoil={isRecoil}
        recoilToday={recoilToday}
        status={status}
        restarts={restarts}
        time={time}
        cycle={cycle}
        tegra={tegra}
      />
      <DevicesPreviewTableCell handleLiveView={handleClickView} deviceId={deviceId} lastImg={lastImg} />
    </TableRow>
  );
};
export default memo(DevicesStatusTableRow);
