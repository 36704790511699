import React, { memo } from "react";
import { InputLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const CustomizedTimePicker = ({ label = "", name, disabled = false, defaultValue }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          <InputLabel>{label}</InputLabel>
          <TimePicker
            ampm={false}
            inputRef={ref}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                error={!!formState?.errors[name]}
                helperText={formState?.errors[name] ? formState?.errors[name].message : ""}
              />
            )}
            disabled={disabled}
          />
        </>
      )}
    />
  );
};

export default memo(CustomizedTimePicker);
