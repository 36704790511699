import * as color from "assets/styles/jss/common/colors";
import * as font from "assets/styles/jss/common/fonts";

const styles = (theme) => ({
  sidebar: {
    display: "flex",
    width: 325,
    marginLeft: "5vh",
    marginTop: "5vh",
    color: color.white,
    "@media (max-width: 960px)": {
      width: "100%",
      marginTop: 0,
      marginLeft: 0,
      "& nav": {
        width: "100%",
      },
    },
  },
  leftSidebar: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 75,
    height: "90vh",
    background: color.menuBackground,
    borderRadius: 20,
    fontSize: "0.75rem",
    fontWeight: font.bold,
  },
  rightSidebar: {
    width: 250,
    background: color.white,
    borderRadius: 20,
    height: "90vh",
    boxShadow: "0px 6px 50px 5px rgba(22, 65, 148, 0.2)",
  },
  logoWrapper: {
    padding: 30,
    width: "95%",
    height: 100,
  },
  submenuWrapper: {
    height: "calc(90vh - 200px)",
    overflowX: "hidden",
    overflowY: "auto",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: color.white,
      borderRadius: 100,
    },
  },
  clicked: {
    background: color.white,
  },
  menuSectionHeader: {
    color: color.darkGray,
    marginTop: 20,
    textTransform: "uppercase",
  },
  menuElement: {
    "&:hover": {
      background: color.blue,
      color: color.white,
      cursor: "pointer",
    },
  },
  menuElementPosition: {
    padding: "4px 10px 4px 20px",
  },
  gap: {
    marginBottom: 16,
  },
  scrollable: {
    overflowY: "auto",
  },
  btn: {
    cursor: "pointer",
    paddingLeft: 16,
    "&:hover": {
      background: color.darkGray,
      color: color.white,
    },
  },
  lock: {
    paddingTop: 10,
    paddingBottom: 10,
    borderTop: "1px solid" + color.gray,
  },
  lockLabel: {
    marginRight: 16,
  },
  lockAlert: {
    marginTop: 20,
    padding: 20,
    background: color.red,
    color: color.white,
  },
  lastItem: {
    marginBottom: 10,
  },
  mobileMItems: {
    backgroundColor: color.menuBackground + " !important",
    minHeight: 600,
    "& ul": {
      paddingLeft: 0,
    },
  },
  visibleSubmenu: {
    backgroundColor: color.white + " !important",
  },
  back: {
    display: "flex",
    padding: "9px 0 9px 20px",
    marginTop: 20,
    cursor: "pointer",
    "&:hover": {
      background: color.submenuGroup,
    },
  },
  mobileCategories: {
    marginTop: 80,
  },
});

export default styles;
