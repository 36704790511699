import { alpha } from "@mui/material/styles";
import * as color from "assets/styles/jss/common/colors";
import { Stack } from "@mui/material";
import React from "react";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";

const iconSx = {
  fontSize: "6rem",
  color: color.green + "!important",
};
const StartBox = ({ onClick }) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        height: "125px",
        width: "125px",
        borderRadius: 1,
        border: 1,
        borderColor: color.menuSelected,
        padding: 1,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: alpha(color.lightGray, 0.4),
          cursor: "pointer",
        },
      }}
      onClick={onClick}
    >
      <PlayCircleOutlineOutlinedIcon sx={iconSx} />
    </Stack>
  );
};
export default StartBox;
